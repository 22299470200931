<template>
  <div class="service">
    <div><b>Размеры экрана:</b> {{ screenSize }}</div>
    <div><b>Размеры доступной области:</b> {{ windowSize }}</div>
    <div><b>User Agent:</b> {{ isMobile() }}</div>
    <div><b>iOS / MacOS:</b> {{ this.$root.isIos() }}</div>
  </div>
</template>

<script>

export default {
  name: 'ServicePage',
  computed: {
    windowSize() {
      return window.screen.availWidth + 'x' + window.screen.availHeight;
    },
    screenSize() {
      return window.screen.width + 'x' + window.screen.height;
    }
  },
  methods: {
    isMobile() {
      console.log(this.$root.isIos());
      return navigator.userAgent;
    }
  }
};
</script>

<style scoped>

</style>