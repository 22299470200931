<template>
  <svg>
    <use :xlink:href="'/images/icons/icons.svg#' + name"></use>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    name: String
  }
};
</script>

<style lang="scss" scoped>

</style>