<template>
  <div class="breadcrumb">
    <div class="breadcrumb__item" v-for="(route, key) in routes" :key="route.path">
      <SvgIcon name="arrow" class="breadcrumb__item-devider" v-if="key !== 0"/>
      <router-link :to="{ path: route.path }" class="breadcrumb__item-link">
        {{ route.name }}
      </router-link>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';

export default {
  name: 'BreadcrumbBlock',
  components: {SvgIcon},
  computed: {
    routes: function () {
      let baseList = this.$route.matched;
      let routes = [];
      baseList.forEach(function (item, key) {
        if (key > 0) {
          if (item.path !== baseList[key - 1].path) routes.push(item);
        } else
          routes.push(item);
      });

      return [{
        name: 'Главная',
        path: '/'
      },
        ...routes];
    }
  }
};
</script>

<style scoped lang="scss">
.breadcrumb {
  display: flex;
  align-items: center;
  padding: 10px 0;

  &__item {
    display: flex;
    align-items: center;
    margin-right: 5px;

    &-devider {
      height: 12px;
      width: 12px;
      fill: #232425;
      margin-right: 5px;

      ._dark & {
        fill: $palette-light_60
      }
    }

    &-link {
      font-size: 14px;
      color: #232425;

      @media screen and (max-width: 1199px) {
        font-size: 12px;
        line-height: 16px;
      }

      ._dark & {
        color: $palette-light_60;
      }
    }
  }
}
</style>