<template>
  <div class="burger">
    <div @click="burgerClick" class="burger-button" v-if="!isActive">
      <div class="bar" :class="{ active: isActive }"></div>
    </div>
    <div class="burger-menu" :class="[{ active: isActive }, className]">
      <div @click="burgerClick" class="burger-button" v-if="isActive">
        <div class="bar" :class="{ active: isActive }"></div>
      </div>
      <slot></slot>
    </div>
    <div class="overlay" :class="{ active: isActive }" @click="burgerClick" v-if="mediaQuery({MaxWidth: 767})"></div>
  </div>
</template>

<script>
import MediaHandlers from '@/mixins/MediaHandlers';

export default {
  name: 'MobileMenu',
  mixins: [
    MediaHandlers
  ],
  props: {
    side: String
  },
  emits: [],
  data() {
    return {
      isActive: false,
      className: '_' + this.side
    };
  },
  methods: {
    burgerClick() {
      if (this.isActive) {
        this.isActive = false;
      } else {
        this.isActive = true;
      }
    }
  },
  watch: {
    '$route.path'() {
      if (this.isActive) {
        this.burgerClick();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$bar-width: 20px;
$bar-height: 2px;
$bar-spacing: 6px;

.bar,
.bar:after,
.bar:before {
  width: $bar-width;
  height: $bar-height;
}

.bar {
  position: relative;
  transform: translateY($bar-spacing);
  background: $palette-red;
  transition: all 0ms 300ms;

  &.active {
    background: rgba(255, 255, 255, 0);

    &:after {
      top: 0;
      transform: rotate(45deg);
      transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }

    &:before {
      bottom: 0;
      transform: rotate(-45deg);
      transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    background: $palette-red;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    background: $palette-red;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
}

.mobile-burger {
  padding: 17px 0;

  & .header-icon {
    stroke: $palette-dark;
  }
}

.overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 10;

  &.active {
    display: block;
  }
}

.burger-button {
  height: 100%;
  position: relative;

  .burger-menu & {
    position: absolute;
    top: 10px;
    right: 25px;

    @media screen and (max-width: 767px) {
      top: 17px;
      right: 20px;
    }
  }
}

.burger-menu {
  position: fixed;
  padding: 0 25px;
  background: $palette-light;
  z-index: 15;
  transition: .5s;

  &._top {
    width: 100%;
    top: -200px;
    left: 0;

    &.active {
      top: 0;
    }
  }

  &._right {
    flex-direction: column;
    width: 270px;
    height: 100%;
    top: 0;
    right: -300px;
    padding: 50px 15px;

    &.active {
      right: 0;
    }
  }
}
</style>