import {createRouter, createWebHistory} from 'vue-router';
import IndexPage from '@/pages/IndexPage';
import RulesPage from '@/pages/RulesPage';
import DeliveryPage from '@/pages/DeliveryPage';
import NotFound from '@/pages/NotFound';
import PolicyPage from '@/pages/PolicyPage';
import ContactsPage from '@/pages/ContactsPage';
import ProfileIndex from '@/pages/profile/ProfileIndex';
import ProfilePage from '@/pages/profile/ProfilePage';
import SettingsPage from '@/pages/profile/SettingsPage';
import SitemapPage from '@/pages/SitemapPage';
import ServicePage from '@/pages/ServicePage';
import ReportsIndex from '@/pages/profile/reports/ReportsIndex';
import ProcessReport from '@/pages/profile/reports/ProcessReport';
import ActivityReport from '@/pages/profile/reports/ActivityReport';
import SectionsReport from '@/pages/profile/reports/SectionsReport';
import AuctionCurrent from '@/pages/profile/AuctionCurrent';
import AuctionHistory from '@/pages/profile/AuctionHistory';

const routes = [
	{
		path: '/',
		name: 'Главная',
		component: IndexPage,
		meta: {
			background: '/images/main_bg_big.jpg',
			main: true,
			layout: 'IndexLayout',
			color: '_dark'
		}
	},
	{
		path: '/rules',
		name: 'Правила торгов',
		component: RulesPage,
		meta: {
			background: '/images/rules_bg.jpg',
			mobile_bg: '/images/rules_bg_m.png',
			color: '_dark'
		}
	},
	{
		path: '/delivery',
		name: 'Условия доставки и оплаты',
		component: DeliveryPage,
		meta: {
			background: '/images/delivery_bg.jpg',
			mobile_bg: '/images/delivery_bg_m.png',
			color: '_dark'
		}
	},
	{
		path: '/policy',
		name: 'Политика конфиденциальности',
		component: PolicyPage
	},
	{
		path: '/contacts',
		name: 'Контакты',
		component: ContactsPage,
		meta: {
			background: '/images/contacts_bg.jpg',
			mobile_bg: '/images/contacts_bg_m.png',
			color: '_dark'
		}
	},
	{
		path: '/profile',
		name: 'Профиль',
		component: ProfileIndex,
		meta: {
			layout: 'ProfileLayout',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'Профиль',
				component: ProfilePage
			},
			{
				path: 'auction',
				name: 'Аукционная площадка',
				component: AuctionCurrent
			},
			{
				path: 'history',
				name: 'История торгов',
				component: AuctionHistory
			},
			{
				path: 'reports',
				name: 'Отчеты',
				component: ReportsIndex,
				children: [
					{
						path: '',
						name: 'Процесс торгов',
						component: ProcessReport
					},
					{
						path: 'activity',
						name: 'Активность пользователей',
						component: ActivityReport,
						meta: {
							requiresManager: true
						}
					},
					{
						path: 'sections',
						name: 'Выбранные типы продукции',
						component: SectionsReport,
						meta: {
							requiresManager: true
						}
					}
				]
			},
			{
				path: 'settings',
				name: 'Настройки',
				component: SettingsPage
			}
		]
	},
	{
		path: '/sitemap',
		name: 'Карта сайта',
		component: SitemapPage
	},
	{
		path: '/service',
		name: 'Сервисная страница',
		component: ServicePage
	},
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		component: NotFound,
		meta: {
			layout: 'IndexLayout',
			color: '_dark'
		}
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	let user = JSON.parse(localStorage.getItem('user'));
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!user.id) {
			next({path: '/', params: {nextUrl: to.fullPath}});
		} else {
			if (to.matched.some(record => record.meta.requiresManager)) {
				if (user.roles == 'MANAGER' || user.roles == 'ROLE_ADMIN') {
					next();
				} else {
					next({path: '/', params: {nextUrl: to.fullPath}});
				}
			} else {
				next();
			}
		}
	} else {
		next();
	}
});

export default router;
