let data = {
	elements: [
		{
			title: 'Новый пароль',
			field: {
				type: 'password',
				name: 'password',
				text: 'Введите пароль'
			}
		},
		{
			title: 'Настройки почтовых уведомлений',
			field: {
				type: 'tablecheckbox',
				positions: [
					{
						name: 'start',
						title: 'Начало торгов'
					},
					{
						name: 'result',
						title: 'Результат торгов'
					},
					{
						name: 'outbid',
						title: 'Ваша ставка перебита'
					}
				],
				items: [
					{
						name: 'section1',
						title: 'Полутуши свиные'
					},
					{
						name: 'section2',
						title: 'Индустриальные охл'
					},
					{
						name: 'section3',
						title: 'Индустриальные зам'
					},
					{
						name: 'section4',
						title: 'Субпродукты свиные в ИУ'
					},
					{
						name: 'section5',
						title: 'Индустриальные продукты'
					}
				]
			}
		}
	],
	values: ['section1||start', 'section1||result', 'section2||result']
};

export default {
	data
};