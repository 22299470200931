<template>
  <div class="container _inner">
    <div class="inner-content">
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProfileLayout',
};
</script>

<style scoped lang="scss">
  .container {
    padding-top: 24px;

    @media screen and (max-width: 1365px) {
      max-width: 100vw;
    }

    &._dark {
      color: $palette-light;
    }
  }
</style>