<template>
  <div class="index-page">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'IndexLayout'
};
</script>

<style lang="scss">
.index {
  &-page {
    height: 100%;
  }
}
</style>
