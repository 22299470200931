<template>
  <div class="list__select-body">
    <div class="list__select-body__item" @click="showSelect">
      <span>{{ text }}</span>
      <SvgIcon name="arrow"/>
    </div>
    <transition name="fade">
      <div class="list__select-popup" v-if="visible">
        <perfect-scrollbar class="process__select-popup__scroll">
          <div class="list__select-popup__scroll-content">
            <slot/>
          </div>
        </perfect-scrollbar>
      </div>
    </transition>
    <transition name="fade">
      <div class="list__select-overlay" v-if="visible" @click="hideSelect"></div>
    </transition>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';

export default {
  name: 'SelectList',
  components: {
    SvgIcon
  },
  props: {
    text: String,
    show: Boolean
  },
  emits: [
    'togglePopup'
  ],
  data: () => ({
    visible: false
  }),
  methods: {
    showSelect() {
      this.visible = true;
      this.$emit('togglePopup', this.visible);
    },
    hideSelect() {
      this.visible = false;
      this.$emit('togglePopup', this.visible);
    }
  },
  watch: {
    show(val) {
      this.visible = val;
    }
  }
};
</script>

<style scoped lang="scss">
.list__select {
  &-body {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    position: relative;
    border: 1px solid $palette-lightgray;
    border-radius: 60px;
    padding: 12px 46px 12px 24px;
    width: 100%;
    max-width: 260px;

    ._filter & {
      border: none;
      justify-content: flex-start;
      padding: 16px 40px 16px 12px;
      width: 100%;
      max-width: unset;

      & * {
        box-sizing: border-box;
      }
    }

    ._history & {
      border: none;
      max-width: unset;
      padding: 8px 40px 8px 16px;

      @media screen and (max-width: 991px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__item {
      font-family: $font-additional;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
      cursor: pointer;
      text-align: right;
      width: 100%;

      ._filter & {
        font-family: $font-main;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-transform: none;
        text-align: left;
      }

      & span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 767px) {
          max-width: 400px;
        }

        @media screen and (max-width: 575px) {
          max-width: 200px;
        }
      }
    }

    & svg {
      position: absolute;
      top: 50%;
      right: 18px;
      width: 8px;
      height: 13px;
      fill: $palette-dark;
      transform: translateY(-50%) rotate(90deg);
      margin: 0 10px;
      z-index: 8;

      ._filter & {
        right: 8px;
        z-index: 1;
      }
    }
  }

  &-popup {
    position: absolute;
    top: 0;
    right: 0;
    width: 260px;
    background: $palette-light;
    padding: 7px 46px 12px 24px;
    border-radius: 16px;
    outline: 1px solid $palette-table;
    z-index: 7;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    ._filter & {
      width: 553px;
      left: 0;
      right: unset;
      padding: 0 16px!important;
      border-radius: 0 18px 16px 16px;
      max-height: 300px;
    }

    &__scroll {
      height: 100%;
    }

    &__item {
      font-family: $font-additional;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
      padding: 7px 8px 7px 11px;
      cursor: pointer;

      &:hover {
        background: #EEEEEE;
      }

      &._active {
        color: #008FD3;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
    z-index: 6;
  }
}
</style>

<style lang="scss">
.list__select-popup__scroll-content {
  & a {
    display: block;
    font-family: $font-additional;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
    padding: 5px 0;
    color: $palette-dark;
    text-align: right;

    &._active {
      color: $palette-red;
    }
  }

  ._filter & {
    max-height: 300px;
  }
}

.auction .list__select-popup {
  @media screen and (max-width: 1199px) {
    padding: 16px 12px!important;
    width: 100%!important;
    max-width: unset;
  }
}
</style>