<template>
	<div class="checkbox" v-if="type === 'checkbox'">
		<label :for="name">
			<input :id="name" :type="type" :name="name" :ref="name" v-model="checked" :value="name" @change="checkboxChange">
			<span class="checkbox-placeholder">
        <SvgIcon name="mark"/>
      </span>
			<span class="checkbox-text" v-if="text" v-html="text"></span>
		</label>
		<div class="input-error" v-if="errorText">
			{{ errorText }}
		</div>
	</div>
	<div class="file" v-else-if="type === 'file'">
		<input :id="name" :type="type" :name="name" :ref="name" @change="fileChange">
		<div class="file-text">{{ text }}</div>
		<div class="file-body">
			<label :for="name">
				<span class="file-body__field" v-if="inputValue">{{ inputValue.name }}</span>
				<span class="file-body__field" v-else>Прикрепить файл</span>
			</label>
			<span class="file-body__close" v-if="inputValue" @click="clearFile">
        <SvgIcon name="cross-bold"/>
      </span>
			<a :href="example.link" download class="file-body__field _example" v-if="example">{{ example.text }}</a>
		</div>
		<div class="input-error" v-if="errorText">
			{{ errorText }}
		</div>
	</div>
	<div class="input _password" :class="{_error: errorText }" v-else-if="type === 'password'">
		<input :type="password.type" :name="name" :required="required" :value="modelValue" :ref="name" :placeholder="text" @change="inputChange">
		<SvgIcon name="eye" :class="password.switcherClass" @click="passwordSwitch"/>
		<transition name="slide-fade">
			<div class="input-error" v-if="errorText">
				{{ errorText }}
			</div>
		</transition>
	</div>
	<div class="input" :class="{_error: errorText }" v-else>
		<input :type="type" :name="name" :value="modelValue" :required="required" :ref="name" :placeholder="text" @change="inputChange">
		<transition name="slide-fade">
			<div class="input-error" v-if="errorText">
				{{ errorText }}
			</div>
		</transition>
	</div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
import Inputmask from 'inputmask';

export default {
	name: 'FormInput',
	props: {
		type: String,
		name: String,
		text: String,
		required: Boolean,
		example: Object,
		mask: String,
		modelValue: {}
	},
	components: {
		SvgIcon
	},
	emits: [
		'update:modelValue'
	],
	data: () => ({
		password: {
			type: 'password',
			switcherClass: '_disabled'
		},
		inputValue: '',
		fileValue: false,
		errorText: false
	}),
	watch: {
		modelValue: function (val) {
			this.inputValue = val;
		}
	},
	mounted() {
		if (this.modelValue) {
			this.inputValue = this.modelValue;
		}

		if (this.mask) {
			if (this.mask === 'phone') {
				let im = new Inputmask('+7 (999) 999-99-99');
				im.mask(this.$refs[this.name]);
			}
			if (this.mask === 'inn') {
				let im = new Inputmask('9999999999[99]');
				im.mask(this.$refs[this.name]);
			}
			if (this.mask === 'ogrn') {
				let im = new Inputmask('9999999999999[99]');
				im.mask(this.$refs[this.name]);
			}
			if (this.mask === 'email') {
				let im = new Inputmask({
					regex: '^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,5}$'
				});
				im.mask(this.$refs[this.name]);
			}
		}
	},
	computed: {
		checked: {
			get() {
				return this.inputValue;
			},
			set(val) {
				this.inputValue = val;
			}
		}
	},
	methods: {
		checkboxChange() {
			this.checkField();
		},
		checkLength() {
			if ((this.inputValue.length > 1) && (this.inputValue.length <= 100)) {
				if ((this.name === 'password') && (this.inputValue.length >= 8) && (this.inputValue.length <= 20)) {
					this.errorText = false;
					this.checkField();
				} else if (this.name !== 'password') {
					this.errorText = false;
					this.checkField();
				} else {
					this.$emit('update:modelValue', '');
					this.errorText = 'Не корректное колличество символов';
				}
			} else {
				this.$emit('update:modelValue', '');
				this.errorText = 'Не корректное колличество символов';
			}
		},
		checkField() {
			if (this.required) {
				if ((this.inputValue !== '') && (this.inputValue !== false)) {
					this.errorText = false;
					this.$emit('update:modelValue', this.inputValue);
				} else {
					this.$emit('update:modelValue', '');
					this.errorText = 'Поле является обязательным для заполнения';
				}
			} else {
				this.errorText = false;
				this.$emit('update:modelValue', this.inputValue);
			}
		},
		inputChange(e) {
			if ((this.mask) && (this.mask !== 'email') && (e.target.value.indexOf('_') !== -1)) {
				e.target.value = '';
			} else {
				this.inputValue = e.target.value;
				this.checkLength();
			}
		},
		fileChange(e) {
			let fileName = e.target.files[0].name;
			let fileType = fileName.match(/\.([^.]+)$/);
			if (fileType[1] === 'pdf') {
				this.inputValue = e.target.files[0];
				this.checkField();
			} else {
				this.errorText = 'Необходимо прикрепить файл PDF';
			}
		},
		clearFile() {
			this.$refs[this.name].value = null;
			this.inputValue = false;
		},
		passwordSwitch() {
			if (this.password.type === 'password') {
				this.password.type = 'text';
				this.password.switcherClass = '_enabled';
			} else {
				this.password.type = 'password';
				this.password.switcherClass = '_disabled';
			}
		}
	}
};
</script>

<style scoped lang="scss">
.input {
  & input {
    padding: 8px 24px;
    border: 1px solid $palette-input;
    border-radius: 60px;
    width: 100%;
    font-family: $font-additional;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

    @media screen and (max-width: 991px) {
      font-size: 14px;
    }

    &:focus {
      outline: none;
      border: 1px solid $palette-dark;
    }
  }

  &._error input {
    border: 1px solid $palette-red;
  }

  &-error {
    font-family: $font-additional;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: $palette-red;
    padding: 5px 0;
  }

  &._password {
    position: relative;

    & input {
      padding: 8px 50px 8px 24px;
    }

    & svg {
      position: absolute;
      right: 25px;
      top: 12px;
      width: 21px;
      height: 13px;
      opacity: .5;
      cursor: pointer;

      &._enabled {
        opacity: 1;
      }
    }
  }
}

.file {
  padding: 8px 0;
  border-top: 1px solid $palette-lightgray;

  &-text {
    font-family: $font-additional;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &-body {
    display: flex;

    &__field {
      font-family: $font-additional;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: #008FD3;
      text-decoration: underline;
      text-decoration-style: dashed;
      cursor: pointer;

      &._example {
        margin-left: 24px;
      }
    }

    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $palette-red;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-left: 4px;

      & svg {
        stroke: $palette-light;
        width: 9px;
        height: 9px;
      }
    }

    & label {
      line-height: 16px;
    }
  }

  & input {
    display: none;
  }
}

.checkbox {
  & input {
    display: none;

    &:checked + .checkbox-placeholder svg {
      display: block;
    }
  }

  & label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #C7C7C7;
    border-radius: 3px;
    cursor: pointer;

    & svg {
      display: none;
      max-width: 90%;
      max-height: 90%;
      fill: $palette-red;
    }
  }

  &-text {
    font-family: $font-additional;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-left: 10px;

		@media screen and (max-width: 991px) {
			font-size: 12px;
		}

		@media screen and (max-width: 575px) {
			margin-left: 3px;
		}
  }
}
</style>