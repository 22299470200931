<template>
  <a class="contacts__item _name" v-if="name">
    <SvgIcon name="user" class="contacts__item-icon"/>
    <span>{{ contacts.name }}</span> </a>
  <a :href="contacts.phone.link" class="contacts__item _phone">
    <SvgIcon name="phone" class="contacts__item-icon"/>
    <span :class="hoverClass" v-if="contacts.phone.text">{{ contacts.phone.text }}</span>
    <span class="placeholderClass" v-if="!contacts.phone.text"></span>
  </a>
  <a :href="contacts.email.link" class="contacts__item _email">
    <SvgIcon name="mail" class="contacts__item-icon"/>
    <span :class="hoverClass">{{ contacts.email.text }}</span> </a>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
import templateData from '/static/data/template.js';
import AppHandlers from "@/mixins/AppHandlers";

export default {
  name: 'ContactsList',
  components: {SvgIcon},
  props: {
    name: Boolean,
    hover: Boolean
  },
  mixins: [
    AppHandlers
  ],
  data: () => ({
    contacts: {
      name: templateData.data.contacts.data.name,
      phone: {
        link: 'tel:' + templateData.data.contacts.data.phone.replace(/[^0-9]/g, ''),
        text: templateData.data.contacts.data.phone
      },
      email: {
        link: 'mailto:' + templateData.data.contacts.data.email,
        text: templateData.data.contacts.data.email
      }
    }
  }),
  computed: {
    hoverClass() {
      let hover = '';
      if (this.hover) {
        hover = '_hovered';
      }
      return hover;
    },

  },
  methods: {
    convertContactsObject (data) {
      if( data.length ) {
        data.forEach((element) => {
          if( element['type'] === 'phone' ) {
            this.contacts[ element['type'] ]['link'] = 'tel:' + element['value'].replace(/[^0-9]/g, '');
            this.contacts[ element['type'] ]['text'] = element['value'];
          }
          else if( element['type'] === 'email' ) {
            this.contacts[ element['type'] ]['link'] = 'mailto:' + element['value'];
            this.contacts[ element['type'] ]['text'] = element['value'];
          }
          else {
            this.contacts[ element['type'] ] = element['value'];
          }
        });
      }
    }
  },
  mounted() {
    this.convertContactsObject(this.$root.contacts);

    this.$mitt.on("update-contacts", (data) => {
      this.convertContactsObject(data);
    });

  }
};
</script>

<style lang="scss" scoped>
._hovered {
  border-radius: 50px;
  transition: .3s;

  &:hover {
    background: none;
  }
}

.contacts {
  &__item {
    display: flex;
    align-items: center;
    color: $palette-light;

    &-icon {
      width: 26px;
      height: 28px;
      margin-right: 5px;
      stroke: $palette-light;
    }

    & span._hovered {
      padding: 0;
    }
  }
}
</style>