import axiosInstance from "./api";
import TokenService from "./token.service";
//import logger from "@fortawesome/vue-fontawesome/src/logger";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
      (config) => {
        const token = TokenService.getLocalAccessToken();

        if (config.url !== "/token/refresh") {
          if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
            //config.headers["x-access-token"] = token; // for Node.js Express back-end
          }
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
  );

  axiosInstance.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const originalConfig = err.config;

        if (originalConfig.url !== "/login_check" && err.response) {
          // Access Token was expired

          if (err.response.status === 401 && (originalConfig._retry === undefined || originalConfig._retry === false)) {
            originalConfig._retry = true;

            if( TokenService.getLocalRefreshToken() ) {
              try {
                const rs = await axiosInstance.post("/token/refresh", {
                  refresh_token: TokenService.getLocalRefreshToken(),
                });

                const { token } = rs.data;

                store.dispatch('auth/refreshToken', token);
                TokenService.updateLocalAccessToken(token);

                return axiosInstance(originalConfig);
              } catch (_error) {
                return Promise.reject(_error);
              }
            }
            else {
              //window.location = '/logout'
            }

          }
        }

        return Promise.reject(err);
      }
  );
};

export default setup;