<template>
  <div class="timer" :class="{_expired: changeColor}">
    <div class="timer-item">
      <div class="timer-item__value">{{ formatTime(hours) }}</div>
      <div class="timer-item__desc" v-if="!hideDesc">часов</div>
    </div>
    <div class="timer-divider">:</div>
    <div class="timer-item">
      <div class="timer-item__value">{{ formatTime(minutes) }}</div>
      <div class="timer-item__desc" v-if="!hideDesc">мин</div>
    </div>
    <div class="timer-divider">:</div>
    <div class="timer-item">
      <div class="timer-item__value">{{ formatTime(seconds) }}</div>
      <div class="timer-item__desc" v-if="!hideDesc">сек</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimerCount',
  props: {
    deadline: {
      type: String,
      required: true
    },
    start: String,
    colorTime: Number,
    speed: {
      type: Number,
      default: 1000
    },
    hideDesc: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'expired',
    'progress',
    'colorChange'
  ],
  data: () => ({
    timeLeft: 0,
    allTime: 0
  }),
  mounted() {
    this.initTimer()
  },
  computed: {
    changeColor() {
      return ((this.colorTime) && (this.hours === 0) && (this.colorTime > this.minutes) && (this.timeLeft > 0));
    },
    seconds() {
      return Math.floor(this.timeLeft % 60);
    },
    minutes() {
      return Math.floor((this.timeLeft / 60) % 60);
    },
    hours() {
      return Math.floor((this.timeLeft / (60 * 60)) % 24);
    }
  },
  methods: {
    initTimer() {
      if( this.deadline === -1 ) {
        this.timeLeft = 0;
      }
      else if (Number(this.deadline) > Math.floor(Date.now() / 1000)) {
        this.timeLeft = Number(this.deadline) - Math.floor(Date.now() / 1000);
        this.allTime = Number(this.deadline) - parseInt(this.start);
        if ((this.colorTime) && ((this.hours === 0) && (this.colorTime > this.minutes))) this.$emit('colorChange', true);
        setTimeout(this.countdown, 1000);
      } else {
        this.$emit('expired');
      }
    },
    formatTime(value) {
      if (value < 10) {
        return '0' + value;
      }
      return value;
    },
    countdown() {
      this.timeLeft = Number(this.deadline) - Math.floor(Date.now() / 1000);
      if (this.timeLeft > 0) {
        setTimeout(this.countdown, this.speed);
        if (this.start) {
          this.$emit('progress', (this.timeLeft / this.allTime) * 100);
        }
      } else {
        this.timeLeft = null;
        this.$emit('expired');
      }
    }
  },
  watch: {
    changeColor(val) {
      this.$emit('colorChange', val);
    },
    deadline() {
      this.initTimer()
    },
  }
};
</script>

<style scoped lang="scss">
.timer {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 28px;

    &__value {
      font-family: $font-main;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: $palette-dark;
      transition: .3s;

      ._expired & {
        color: $palette-red;

        ._complete & {
          color: $palette-dark;
        }
      }
    }

    &__desc {
      font-family: $font-main;
      font-size: 9px;
      font-weight: 400;
      line-height: 16px;
      color: #9A9A9A;
    }
  }

  &-divider {
    font-family: $font-main;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #9A9A9A;
  }
}
</style>