<template>
  <div class="index-content__logo" :style="bgCss">
    <img class="index-content__logo-image" :style="logoCss" alt="logo" :src="logo">
  </div>
</template>

<script>
export default {
  name: 'IndexLogo',
  data() {
    return {
      logo: '/images/logo.png',
      backgroundImg: 'url(/images/meat.png)'
    };
  },
  computed: {
    bgCss() {
      return {
        backgroundImage: this.backgroundImg,
        transform: this.$root.paralaxMove('block', 10)
      };
    },
    logoCss() {
      return {
        transform: this.$root.paralaxMove('block', 15)
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.index-content__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  max-height: 820px;

  @media screen and (max-width: 991px) {
    background-size: contain;
    background-position: center !important;
    height: 145%;
    margin-top: -150px;
  }
  @media screen and (max-width: 767px) {
    background-size: 90%;
    background-position: top !important;
    height: 180%;
    margin-top: -80px;
  }

  @media screen and (max-width: 575px) {
    background-size: 125%;
    background-position: top center !important;
    height: 230%;
    margin-top: -80px;
  }

  &-image {
    position: absolute;
    margin-top: -35%;
    margin-left: 15%;
    width: 330px;

    @media screen and (max-width: 1600px) {
      margin-top: -30%;
      margin-left: 14%;
      width: 264px;
    }
    @media screen and (max-width: 1365px) {
      width: 264px;
    }
    @media screen and (max-width: 1199px) {
      width: 206px;
    }
    @media screen and (max-width: 991px) {
      margin-left: 8%;
      width: 250px;
    }
    @media screen and (max-width: 767px) {
      margin-top: -8%;
      width: 185px;
    }
    @media screen and (max-width: 575px) {
      margin-top: calc(-20% * 4);
      width: 155px;
    }
  }
}
</style>