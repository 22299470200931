<template>
	<div class="remind">
		<div class="remind-form" v-if="phase === 'form'">
			<div class="remind-form__container">
				<div class="remind-form__title">Восстановление пароля</div>
				<div class="remind-form__text">
					Введите зарегистрированный ИНН, и мы отправим ссылку для восстановления пароля на все E-mail, связанные с данным ИНН
				</div>
				<div class="error-text" v-if="errorText !== ''">{{ errorText }}</div>
				<FormInput type="text" name="INN" text="Номер вашего ИНН" :required="true" mask="inn" class="remind-form__field" v-model="formResult['INN']" ref="INN"/>
			</div>
			<div class="remind-form__control">
				<ButtonItem text="Запросить восстановление пароля" color="red" class="remind-form__control-submit" @click="submitRemind"/>
				<div class="remind-form__control-nav">
					<ButtonItem text="Авторизация" color="gray" @click="this.$root.showAuth"/>
					<ButtonItem text="Регистрация" color="gray" @click="this.$root.showRegister"/>
				</div>
			</div>
		</div>
		<div class="remind-success" v-if="phase === 'success'">
			<div class="remind-success__text">
				Контрольная строка для восстановления доступа успешно отправлена на все E-mail, связанные с зарегистрированным ИНН
			</div>
		</div>
		<div class="remind-change" v-if="phase === 'change'">
			<div class="remind-form__container">
				<div class="remind-form__title">Изменение пароля</div>
				<div class="remind-form__text">
					Пожалуйста, введите внизу новый пароль
				</div>
				<div class="error-text" v-if="errorText !== ''">{{ errorText }}</div>
				<FormInput type="password" name="password" text="Новый пароль" :required="true" class="remind-form__field" v-model="formResult['password']" ref="password"/>
			</div>
			<div class="remind-form__control">
				<ButtonItem text="Сохранить" color="red" class="remind-form__control-submit" @click="submitChange"/>
			</div>
		</div>
		<div class="remind-success" v-if="phase === 'changesucccess'">
			<div class="remind-success__text">
				Пароль успешно изменен
			</div>
		</div>
	</div>
</template>

<script>
import FormInput from '@/components/Elements/FormInput';
import ButtonItem from '@/components/Elements/ButtonItem';
import AppHandlers from '@/mixins/AppHandlers';

export default {
	name: 'RemindForm',
	components: {
		ButtonItem,
		FormInput
	},
	mixins: [
		AppHandlers
	],
	data: () => ({
		phase: 'form',
		errorText: '',
		formResult: {
			'INN': '',
			'password': ''
		}
	}),
	mounted() {
		let urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get('password')) {
			this.phase = 'change';
		}
	},
	methods: {
		async submitRemind() {
			this.$root.showPreloader();
			this.$refs['INN'].checkField();
			if (this.formResult['INN'] !== '') {
				let data = {
					inn: this.formResult.INN,
					password: '',
					hash: ''
				};
				let response = await this.postData('/passwords', data);
				if (response.error === '') {
					this.phase = 'success';
				} else {
					this.errorText = response.error;
				}
			}
			this.$root.hidePreloader();
		},
		async submitChange() {
			this.$root.showPreloader();
			this.$refs['password'].checkField();
			if (this.formResult['password'] !== '') {
				let urlParams = new URLSearchParams(window.location.search);
				let data = {
					inn: '',
					password: this.formResult['password'],
					hash: urlParams.get('hash')
				};
				let response = await this.postData('/passwords', data);
				if (response.error === '') {
					this.phase = 'changesucccess';
				} else {
					this.errorText = response.error;
				}
			}
			this.$root.hidePreloader();
		}
	}
};
</script>

<style scoped lang="scss">
.remind {
  height: 100%;


  &-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__title {
      font-family: $font-additional;
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;
      margin-bottom: 24px;

      @media screen and (max-width: 1199px) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    &__text {
      font-family: $font-additional;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 16px;

      @media screen and (max-width: 991px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__field {
      margin-bottom: 48px;

      @media screen and (max-width: 767px) {
        margin-bottom: 24px;
      }
    }

    &__control {
      &-submit {
        margin-bottom: 24px;

        @media screen and (max-width: 991px) {
          margin-bottom: 16px;
        }
      }

      &-nav {
        margin-bottom: 20px;

        & > * {
          &:first-child {
            margin-right: 20px;

            @media screen and (max-width: 991px) {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }

  &-success {
    font-family: $font-additional;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
  }
}
</style>