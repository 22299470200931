<template>
	<div class="authform">
		<div class="authform-body">
			<FormInput type="text" name="INN" text="Номер вашего ИНН" :required="true" mask="inn" class="authform-body__field" v-model="formResult['INN']" ref="INN"/>
			<FormInput type="password" name="password" text="Введите пароль" :required="true" class="authform-body__field" v-model="formResult['password']" ref="password" @keyup.enter="autorize"/>
			<div class="error-text" v-if="errorText">
				{{ errorText }}
			</div>
			<a href="#" class="authform-link" @click="this.$root.showRemind">Восстановление пароля</a>
		</div>
		<div class="authform-control">
			<ButtonItem text="Войти" color="red" @click="autorize" @keyup.enter="autorize"/>
			<ButtonItem text="Регистрация" color="gray" @click="this.$root.showRegister"/>
		</div>
	</div>
</template>

<script>
import FormInput from '@/components/Elements/FormInput';
import ButtonItem from '@/components/Elements/ButtonItem';
import store from '@/store';
import TokenService from '@/services/token.service';

export default {
	name: 'AuthForm',
	components: {ButtonItem, FormInput},
	emits: {
		auth: null,
		submit: null
	},
	data: () => ({
		isAuth: false,
		formResult: {
			'INN': '',
			'password': ''
		},
		errorText: ''
	}),
	methods: {
		async autorize() {
			this.$root.showPreloader();
			if ((this.formResult['INN'] !== '') && (this.formResult['password'] !== '')) {
				if (this.formResult['INN'] === 'invalid') {
					this.errorText = 'Данный пользователь не найден, проверьте корректность заполненных полей';
					this.$refs['INN'].checkField();
					this.$refs['password'].checkField();
				} else {
					await this.$store.dispatch('auth/login', {
						username: this.formResult['INN'],
						password: this.formResult['password']
					}).then(
						async () => {
							await store.dispatch('auth/getUser', TokenService.getLocalAccessToken());
							this.isAuth = true;
							this.event();
						},
						(error) => {
							this.errorText = 'Не корректная пара логин/пароль.';
							console.log(error);
						}
					);

				}
			} else {
				this.$refs['INN'].checkField();
				this.$refs['password'].checkField();
			}
			this.$root.hidePreloader();
		},
		event() {
			this.$emit('auth', this.isAuth);
		}
	}
};
</script>

<style scoped lang="scss">
.authform {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-body {
    flex-grow: 1;

    &__field {
      margin-bottom: 16px;
    }
  }

  &-control {
    margin-bottom: 20px;

    @media screen and (max-width: 575px) {
      margin-bottom: 0;
    }

    & > * {
      margin-right: 32px;

      @media screen and (max-width: 374px) {
        margin-right: 20px;
      }
    }
  }

  &-link {
    display: inline-block;
    margin-bottom: 48px;
    color: #008FD3;
    font-family: $font-additional;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-decoration: underline;
    text-decoration-style: dashed;
  }
}
</style>