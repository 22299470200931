<template>
  <nav class="profile-menu">
    <div class="profile-menu__item _hovered" v-for="menuItem in menu" :key="menuItem.text" :class="{ _active: isActive(menuItem) }">
      <router-link :to="menuItem.link" class="profile-menu__item-link">{{ menuItem.text }}</router-link>
    </div>
  </nav>
</template>

<script>
import templateData from '/static/data/template.js';

export default {
  name: 'ProfileMenu',
  data() {
    return {
      menu: templateData.data.profile.menu
    };
  },
  methods: {
    isActive(item) {
      let active = false;
      if (item.link === this.$route.path) {
        active = true;
      }
      return active;
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-menu {
  display: flex;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 991px) {
    padding: 4px 0;
    max-width: 572px;
    justify-content: space-between;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: start;
    padding-bottom: 15px;
    border-bottom: 1px solid $palette-gray;
  }

  &__item {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 8px 0;
    transition: .3s;
    margin-right: 48px;

    ._inner & a {
      color: #232425;
    }

    @media screen and (max-width: 1365px) {
      font-size: 15px;
    }
    @media screen and (max-width: 991px) {
      color: $palette-dark;
      margin-right: 0;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }

    & a {
      font-size: 17px;
      color: $palette-light;

      @media screen and (max-width: 1356px) {
        font-size: 15px;
      }

      @media screen and (max-width: 991px) {
        font-size: 14px;
        color: $palette-dark;
      }
    }
  }
}

._hovered {
  border-radius: 0;
  border-bottom: 2px solid transparent;

  &:hover, &._active {
    border-bottom: 2px solid $palette-red;

    @media screen and (max-width: 767px) {
      border: none;
    }
  }

  & a {
    border-radius: 50px;
    transition: .3s;
  }

  @media screen and (max-width: 991px) {
    padding: 8px 5px;
  }
}
</style>