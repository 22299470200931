<template>
	<div class="mobile" v-if="mediaQuery({MaxWidth: 767})">
		<div class="mobile-header">
			<div class="mobile-header__title">
				<div class="mobile-header__item _title" @click="sortBy('articul')">
					<span>{{ columns.articul }} </span>
					<span class="arrows" v-if="checkSort('articul')" :class="cssSort('articul')"><SvgIcon name="arrow" class="up"/><SvgIcon name="arrow" class="down"/></span>
				</div>
				<SvgIcon name="arrow" class="mobile-header__title-arrow" :class="{_active: mobileSort}" @click="showSort"/>
			</div>
			<transition name="slidedown">
				<div class="mobile-header__content" v-if="mobileSort">
					<div class="mobile-header__item" v-for="(column, key) in tableColumns" @click="sortBy(key)" :class="{ active: sortKey === key }" :key="key">
						<span v-if="key === 'bid'">{{ column.name }}</span> <span v-else>{{ column }} </span>
						<span class="arrows" v-if="checkSort(key)" :class="cssSort(key)"><SvgIcon name="arrow" class="up"/><SvgIcon name="arrow" class="down"/></span>
					</div>
				</div>
			</transition>
		</div>
		<div class="mobile-grid">
			<div class="mobile-grid__row" v-for="(item, itemKey) in filtredItems" :key="itemKey" :class="[{_notavailable: !item.available}, item.status]">
				<div class="mobile-grid__row-cell" @click="mobileDetail(itemKey)">
					<span>{{ item.articul }}</span>
				</div>
				<div class="mobile-grid__row-cell" @click="mobileDetail(itemKey)">
					<span>{{ item.date.day }} {{ item.date.value }}</span>
				</div>
				<div class="mobile-grid__row-cell _timer" @click="mobileDetail(itemKey)">
					<SvgIcon name="infinity" class="infinity-icon" v-if="item[itemKey] === 'infinity'"/>
					<TimerCount :deadline="item.timer" :color-time="colorTime" :hide-desc="true" @expired="timerExpired(itemKey)" v-else/>
				</div>
				<div class="mobile-grid__row-cell _bid" :class="{_active: mobileItems[itemKey]}">
					<div class="bid-button" :class="{_active: (item.status === 'lose') && (item.available)}" @click="doBid(itemKey)">BID</div>
					<div class="bid-color" :style="{backgroundColor: colors[item.status].color}"></div>
				</div>
				<div class="mobile-grid__row-cell" @click="mobileDetail(itemKey)">
					<span>{{ columns.bid.base }}</span> <span>{{ item.bid.base }}</span>
				</div>
				<div class="mobile-grid__row-cell" @click="mobileDetail(itemKey)">
					<span>{{ columns.bid.current }}</span> <span>{{ item.bid.current }}</span>
				</div>
				<div class="mobile-grid__row-cell" @click="mobileDetail(itemKey)">
					<span>{{ columns.bid.my }}</span> <span>{{ item.bid.my }}</span>
				</div>
				<div class="mobile-grid__row-cell _detail" v-if="mobileItems[itemKey]" @click="mobileDetail(itemKey)">
					<div class="mobile-detail">
						<div class="mobile-detail__item">
							<b>{{ columns.articul }}</b><span>{{ item.articul }}</span>
						</div>
						<div class="mobile-detail__item">
							<b>{{ columns.date }}</b><span>{{ item.date.day }} {{ item.date.value }}</span>
						</div>
						<div class="mobile-detail__item">
							<b>{{ columns.name }}</b><span>{{ item.name }}</span>
						</div>
						<div class="mobile-detail__item">
							<b>Категория</b><span>{{ item.section }}</span>
						</div>
						<div class="mobile-detail__item">
							<b>Весб кг</b><span>{{ item.weigth }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>


	<div class="desktop" v-if="mediaQuery({MinWidth: 768})">
		<div class="desktop-header">
			<div class="desktop-header__item" v-for="(column, key) in tableColumns" @click="sortBy(key)" :class="[{ active: sortKey === key }, key]" :key="key">
				<span v-if="key === 'bid'">{{ column.name }}</span> <span v-else>{{ column }} </span>
				<span class="arrows" v-if="checkSort(key)" :class="cssSort(key)"><SvgIcon name="arrow" class="up"/><SvgIcon name="arrow" class="down"/></span>
			</div>
		</div>
		<div class="desktop-grid__wrapper">
			<perfect-scrollbar class="desktop-grid">
				<div class="desktop-grid__row" v-for="(item, itemKey) in filtredItems" :key="itemKey" :class="[{_notavailable: !item.available}, item.status]">
					<div class="desktop-grid__row-item" v-for="(column, key) in tableColumns" :class="key" :key="key">
						<div class="timer" v-if="key === 'timer'">
							<SvgIcon name="infinity" class="infinity-icon" v-if="item[key] === 'infinity'"/>
							<TimerCount :deadline="item[key]" :color-time="colorTime" @expired="timerExpired(itemKey)" v-else/>
						</div>
						<div class="date" v-else-if="key === 'date'">
							{{ item[key].day }}<br/>{{ item[key].value }}
						</div>
						<div class="bid" v-else-if="key === 'bid'">
							<div class="bid-info">
								<div class="bid-info__item">
									<span>{{ column.base }}</span><span>{{ formatPrice(item[key].base) }}</span></div>
								<div class="bid-info__item">
									<span>{{ column.current }}</span><span>{{ formatPrice(item[key].current) }}</span>
								</div>
								<div class="bid-info__item">
									<span>{{ column.my }}</span><span>{{ formatPrice(item[key].my) }}</span>
								</div>
							</div>
							<div class="bid-button" :class="{_active: (item.status === 'lose') && (item.available)}" @click="doBid(itemKey)">BID</div>
							<div class="bid-color" :style="{backgroundColor: setColor(colors, item)}"></div>
						</div>
						<div v-else v-html="item[key]"></div>
					</div>
				</div>
			</perfect-scrollbar>
		</div>
	</div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
import TimerCount from '@/components/Elements/TimerCount';
import MediaHandlers from '@/mixins/MediaHandlers';
import {socketEmit} from '@/helpers/Socket';

export default {
	name: 'TableAuction',
	components: {TimerCount, SvgIcon},
	props: {
		items: Array,
		columns: Object,
		colors: Object,
		excludeSort: Array,
		colorTime: Number,
		bidSize: Number,
		bidTime: Number,
		deadline: String,
		filter: Object,
		limit: Number,
		sort: {
			type: Boolean,
			default: true
		}
	},
	emits: [
		'expiredLimit',
		'timesUp'
	],
	mixins: [
		MediaHandlers
	],
	data: () => ({
		sortKey: '',
		sortOrder: {},
		bidCount: 0,
		tableColumns: {},
		filtredItems: {},
		mobileItems: {},
		mobileSort: false
	}),
	mounted() {
		this.tableColumns = this.columns;
		this.filterItems();
	},
	methods: {
		setColor(colors, item) {
			if (item.available) {
				return colors[item.status].color;
			} else {
				return colors['complete'].color;
			}
		},
		doBid(key) {
			if ((this.filtredItems[key].available) && (this.filtredItems[key].status !== 'lead')) {
				this.$root.showPreloader();
				socketEmit('rate-add', {lotId: this.filtredItems[key].id});
			}
		},
		timerExpired(key) {
			if (this.filtredItems[key].timer !== 'infinity') {
				this.filtredItems[key].available = false;
			}
			this.filterItems();
		},
		cssSort(key) {
			if (this.sortOrder[key]) {
				return this.sortOrder[key] > 0 ? 'asc' : 'desc';
			} else {
				return false;
			}
		},
		sortBy(key) {
			if (this.sort) {
				if (this.excludeSort) {
					if (this.excludeSort.includes(key))
						return false;
				}
				this.sortKey = key;
				this.sortOrder[key] = this.sortOrder[key] ? this.sortOrder[key] * -1 : 1;
				this.filterItems();
			}
		},
		filterItems() {
			let sortKey = this.sortKey;
			let order = this.sortOrder[this.sortKey] || 1;
			let items = this.items;

			if (this.filter) {
				let filter = {};
				for (let filterKey in this.filter) {
					let propKey = filterKey.split('-');
					if (propKey[0] === 'status_type') {
						propKey[0] = 'status';
					}
					if (!filter[propKey[0]]) {
						filter[propKey[0]] = [];
					}
					if ((propKey[0] === 'status') || (propKey[0] === 'available')) {
						if (propKey[0] === 'status') {
							filter[propKey[0]].push(propKey[1]);
						}
						if (propKey[0] === 'available') {
							filter[propKey[0]].push(propKey[1] === 'true');
						}
					} else {
						filter[propKey[0]].push(this.filter[filterKey]);
					}
				}

				for (let filterKey in filter) {
					let propKey = filterKey.split('-')[0];

					items = items.filter(function (element) {
						if (filterKey === 'date') {
							if (filter[filterKey].includes(element[propKey].day)) return true;
						}
						return filter[filterKey].includes(element[propKey]);
					});
				}
			}

			if (sortKey) {
				items = items.slice().sort(function (a, b) {
					if (sortKey === 'bid') {
						a = a[sortKey].current;
						b = b[sortKey].current;
					} else {
						a = a[sortKey];
						b = b[sortKey];
					}
					return (a === b ? 0 : a > b ? 1 : -1) * order;
				});
			}
			this.filtredItems = items;
		},
		checkSort(key) {
			if (this.excludeSort) {
				return !this.excludeSort.includes(key);
			} else {
				return true;
			}
		},
		mobileDetail(key) {
			this.mobileItems[key] = !this.mobileItems[key];
		},
		showSort() {
			this.mobileSort = !this.mobileSort;
		},
		formatPrice(val) {
			let formatedPrice;

			if (val !== '-') {
				if ((val % 1) !== 0) {
					formatedPrice = val.toFixed(2);
				} else {
					formatedPrice = val;
				}
			} else {
				formatedPrice = val;
			}

			return formatedPrice;
		}
	},
	watch: {
		filter() {
			this.filterItems();
		},
		items() {
			this.filterItems();
		}
	}

};
</script>

<style scoped lang="scss">
.mobile {
  &-header {
    position: relative;

    &__title {
      position: relative;
      background-color: $palette-red;
      padding-right: 32px;
      border-radius: 16px 16px 0 0;

      & ._title {
        background: none;
      }

      &-arrow {
        position: absolute;
        top: 50%;
        right: 12px;
        width: 12px;
        height: 12px;
        stroke: $palette-light;
        transform: translateY(-50%) rotate(90deg);
        transition: .3s;

        &._active {
          transform: translateY(-50%) rotate(-90deg);
        }
      }
    }

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 32px);
      z-index: 1;
      background-color: $palette-red;
      border-radius: 16px;

      & .mobile-header__item {
        border-top: 1px solid #F26E6F;

        &:first-child {
          border: none;
        }
      }
    }

    &__item {
      position: relative;
      cursor: pointer;
      user-select: none;
      font-family: $font-additional;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      color: $palette-light;
      text-align: left;
      padding: 8px 16px;

      &.active {
        & .arrow {
          opacity: 1;
        }
      }

      & .arrows {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 1px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);

        @media screen and (max-width: 1365px) {
          right: 5px;
        }

        & svg {
          width: 9px;
          height: 9px;
          stroke: $palette-light;
          opacity: .5;

          &.up {
            transform: rotate(-90deg);
          }

          &.down {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &-grid {
    &__row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 75px;

      @media screen and (max-width: 575px) {
        grid-template-columns: 3fr 4fr 3fr 55px;
      }

      &:first-child {
        border-top: 1px solid $palette-lightgray;
      }

      &:nth-child(2n) {
        background: $palette-table;
      }

      &-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 6px 8px;
        font-family: $font-main;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        border-width: 0 0 1px 1px;
        border-style: solid;
        border-color: $palette-lightgray;

        @media screen and (max-width: 575px) {
          padding: 6px 5px;
          flex-direction: column;
        }

        & span {
          margin: 0 5px;
        }

        ._notavailable & > * {
          opacity: .5;
        }

        &._detail {
          grid-column: 1 / 4;
          padding: 8px 16px;
        }

        &._bid {
          grid-column: 4 / 5;
          grid-row: 1 / 3;
          display: flex;
          align-items: center;
          justify-content: center;

          &._active {
            grid-row: 1 / 4;
          }
        }


        & .bid {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 10px;

          &-info {
            width: 100%;

            &__item {
              display: flex;
              align-items: center;

              & span {
                &:first-child {
                  width: 60%;
                }

                &:last-child {
                  width: 40%;
                }
              }
            }
          }

          &-button {
            width: 40px;
            height: 40px;
            font-family: $font-additional;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            padding: 8px;
            background: $palette-table;
            border-radius: 50%;

            &._active {
              color: $palette-light;
              background: $palette-red;
              cursor: pointer;
            }
          }

          &-color {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 4px;
            opacity: 1;
          }
        }
      }
    }
  }

  &-detail {
    display: grid;
    grid-gap: 8px;
    flex-grow: 1;
    width: 100%;

    &__item {
      display: grid;
      grid-template-columns: 2fr 7fr;
      font-family: $font-additional;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.desktop {
  &-header {
    display: grid;
    grid-template-columns: 6fr 6fr 12fr 4fr 14fr 7fr 12fr;
    border-radius: 16px 16px 0 0;
    background-color: $palette-red;
    overflow: hidden;

    &__item {
      cursor: pointer;
      user-select: none;
      font-family: $font-additional;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: $palette-light;
      text-align: left;
      position: relative;
      border-right: 1px solid $palette-lightgray;
      padding: 10px 20px;
      overflow: hidden;

      @media screen and (max-width: 1365px) {
        padding: 12px;
      }

      @media screen and (max-width: 991px) {
        padding: 8px;
      }

      &:last-child {
        border: none;
      }

      @media screen and (max-width: 1365px) {
        font-size: 12px;
        line-height: 16px;
      }

      &.active {
        background: $palette-red-dark;

        & .arrow {
          opacity: 1;
        }
      }

      & .arrows {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 3px;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);

        @media screen and (max-width: 1365px) {
          right: 5px;
        }

        & svg {
          width: 9px;
          height: 9px;
          stroke: $palette-light;
          opacity: .7;

          &.up {
            transform: rotate(-90deg);
          }

          &.down {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &-grid {
    max-height: calc(100vh - 280px);
    margin-bottom: -1px;

    &__wrapper {
      border-width: 0 0 1px 1px;
      border-style: solid;
      border-color: $palette-lightgray;
      border-radius: 0 0 16px 16px;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
    }

    &__row {
      display: grid;
      grid-template-columns: 6fr 6fr 12fr 4fr 14fr 7fr 12fr;
      border-bottom: 1px solid $palette-lightgray;

      &:last-child {
        border: none;
      }

      &:nth-child(2n) {
        background: $palette-table;
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-main;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        position: relative;
        border-right: 1px solid $palette-lightgray;
        padding: 10px 20px;
        overflow: hidden;

        @media screen and (max-width: 1365px) {
          padding: 12px;
          font-size: 12px;
          line-height: 16px;
        }

        @media screen and (max-width: 991px) {
          padding: 8px;
        }

        ._notavailable & > * {
          opacity: .5;
        }

        & * {
          opacity: 1;
          transition: .3s;
        }

        &:last-child {
          border: none;
        }

        & .bid {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-right: 10px;

          &-info {
            width: 100%;

            &__item {
              display: flex;
              align-items: center;

              & span {
                &:first-child {
                  width: 60%;
                }

                &:last-child {
                  width: 40%;
                }
              }
            }
          }

          &-button {
            font-family: $font-additional;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            padding: 8px;
            color: $palette-dark;
            background: $palette-graybutton;
            border-radius: 50%;
            cursor: pointer;

            &._active {
              color: $palette-light;
              background: $palette-red;
            }

            &:hover {
              color: $palette-light;
              background: $palette-red-dark;
            }

            &._is_blocked {
              color: $palette-light;
              background: $palette-dark;
            }
          }

          &-color {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 16px;
            opacity: 1;

            @media screen and (max-width: 1365px) {
              width: 8px;
            }
          }
        }
      }
    }
  }
}

.arrows.asc svg.up {
  opacity: 1;
}

.arrows.desc svg.down {
  opacity: 1;
}

.infinity-icon {
  fill: $palette-dark;
  width: 50px;
  height: 25px;
}
</style>

<style lang="scss">
.mobile-grid__row-cell._timer {
  & .timer {
    &-item {
      width: 20px;

      &__value {
        font-size: 14px;
        line-height: 16px;
      }

    }

    &-divider {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.lead .bid-button, ._notavailable .bid-button {
  cursor: default !important;

  &:hover {
    color: $palette-dark !important;
    background: $palette-graybutton !important;
  }
}
</style>