<template>
  <div class="sitemap">
    <div class="sitemap-main">
      <router-link to="/">Главная</router-link>
    </div>
    <div class="sitemap-list">
      <div class="sitemap-list__item" v-for="(item, key) in sitemap" :key="key">
        <router-link :to="item.link">{{ item.text }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import templateData from '/static/data/template.js';

export default {
  name: 'SitemapPage',
  data: () => ({
    sitemap: [
      ...templateData.data.header.menu,
      ...templateData.data.profile.menu
    ]
  })
};
</script>

<style scoped lang="scss">
.sitemap {
  margin-bottom: 30px;

  &-main {
    & a {
      font-family: $font-additional;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      color: $palette-dark;
      text-transform: uppercase;

      @media screen and (max-width: 991px) {
        font-size: 16px;
      }

      &:hover {
        color: $palette-red;
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 15px;
    margin-top: 25px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: -20px;
      width: 1px;
      height: calc(100% - 20px);
      background: $palette-red;
    }

    &__item {
      display: inline-block;
      position: relative;
      padding-left: 40px;
      margin: 7px 0;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: -15px;
        height: 36px;
        width: 60px;
        border: 1px solid $palette-red;
        border-radius: 20px;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 1px;
        top: -15px;
        height: 18px;
        width: 65px;
        background: $palette-light;
      }

      & a {
        position: relative;
        display: inline-block;
        font-family: $font-additional;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        color: $palette-dark;
        background: $palette-light;
        padding: 5px;
        z-index: 1;

        @media screen and (max-width: 991px) {
          font-size: 16px;
        }

        &:hover {
          color: $palette-red;
        }
      }
    }
  }
}
</style>