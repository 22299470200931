import api from '@/services/api';
import TokenService from '@/services/token.service';

export default {
	methods: {
		getData: async (url) => {
			let result = {},
				token = TokenService.getLocalAccessToken();
			await api.get(url, token).then((response) => {
				result = response.data;
			}).catch((response) => {
				result = response.response.data;
			});
			return result;
		},
		getDataWithoutToken: async (url) => {
			let result = {};
			await api.get(url).then((response) => {
				result = response.data;
			}).catch((response) => {
				result = response.response.data;
			});
			return result;
		},
		postData: async (url, data) => {
			let result = {},
				token = TokenService.getLocalAccessToken();
			await api.post(url, data, token).then((response) => {
				result = response.data;
			}).catch((response) => {
				result = response.response.data;
			});
			return result;
		},
		postDataFormData: async (url, data) => {
			let result = {};

			await api.post(url, data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then((response) => {
				result = response.data;
			}).catch((response) => {
				result = response.response.data;
			});
			return result;
		},
		managerCheck() {
			let user = JSON.parse(localStorage.getItem('user'));
			return (user.roles === 'MANAGER') || (user.roles === 'ROLE_ADMIN');
		}
	}
};