<template>
  <div v-html="content"></div>
</template>

<script>
//import templateData from '/static/data/template.js';
import AppHandlers from "@/mixins/AppHandlers";

export default {
  name: 'RulesPage',
  mixins: [
    AppHandlers
  ],
  data: () => ({
    //rules: templateData.data.rules.content,
    content: ''
  }),
  async mounted() {
    this.$root.showPreloader();
    try {
      let response = await this.getDataWithoutToken('/pages/rules');
      this.content = response.content;
    }
    catch(e) {
      //this.content = e.response.data['hydra:description'];
      this.content = '';
    }
    this.$root.hidePreloader();
  }
};
</script>

<style lang="scss">
.rules {
  max-width: 616px;
  padding-left: 18px;

  @media screen and (max-width: 1365px) {
    max-width: 50%;
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }

  &__item {
    font-size: 16px;
    line-height: 24px;
    padding-left: 10px;
    margin-bottom: 15px;

    @media screen and (max-width: 991px) {
      font-size: 14px;
    }

    &:first-child {
      @media screen and (max-width: 767px) {
        max-width: 50%;
      }

      @media screen and (max-width: 575px) {
        max-width: 100%;
      }
    }
  }
}
</style>