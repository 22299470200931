<template>
  <div class="contacts">
    <div class="contacts-block">
      <div class="contacts-block__item _contacts">
        <ContactsList :name="true" :hover="false"/>
      </div>
      <div class="contacts-block__item" v-for="(item, key) in adress" :key="key">
        <div class="contacts-block__item-title" v-html="item.title"></div>
        <div class="contacts-block__item-text" v-html="item.content"></div>
      </div>
    </div>
    <YandexMap :settings="mapSettings" :coordinates="getMapCenter" :controls="[]" :zoom="6" class="contacts-map">
      <YandexMarker v-for="(marker, key) in map" :key="key" :markerId="key" :coordinates="marker.coords" :properties="{preset: 'islands#circleIcon'}" :options="{iconColor: '#EE4142'}"/>
    </YandexMap>
  </div>
</template>

<script>
import templateData from '/static/data/template.js';
import ContactsList from '@/components/ContactsList';
import {YandexMap, YandexMarker} from 'vue-yandex-maps';
import AppHandlers from "@/mixins/AppHandlers";

export default {
  name: 'ContactsPage',
  components: {
    ContactsList,
    YandexMap,
    YandexMarker
  },
  mixins: [
    AppHandlers
  ],
  data: () => ({
    //adress: templateData.data.contacts.adress,
    adress: [],
    map: templateData.data.contacts.map,
    mapSettings: {
      apiKey: 'd8d52006-c705-422f-8f6e-cf413fd1c8aa',
      lang: 'ru_RU',
      coordorder: 'latlong',
      enterprise: false,
      version: '2.1'
    },
  }),
  computed: {
    getMapCenter() {
      let center = [],
          markers = this.map,
          min = [100, 100],
          max = [0, 0];
      markers.forEach(function (item) {
        min = [
          item.coords[0] < min[0] ? item.coords[0] : min[0],
          item.coords[1] < min[1] ? item.coords[1] : min[1]
        ];
        max = [
          item.coords[0] > max[0] ? item.coords[0] : max[0],
          item.coords[1] > max[1] ? item.coords[1] : max[1]
        ];
      });
      center = [
        min[0] + ((max[0] - min[0]) / 2),
        min[1] + ((max[1] - min[1]) / 2)
      ];

      return center;
    }
  },
  async mounted() {
    this.$root.showPreloader();
    try {
      let response = await this.getDataWithoutToken('/addresses');
      //this.content = response.content;
      this.adress = [];
      //response['hydra:member']
      response.forEach((element) => {
        this.adress.push({
          title: element['name'],
          content: element['content'],
        });
      });

      console.log(this.adress)
    }
    catch(e) {
      //this.content = e.response.data['hydra:description'];
    }
    this.$root.hidePreloader();
  }
};
</script>

<style scoped lang="scss">
.contacts {
  max-width: 616px;

  @media screen and (max-width: 1250px) {
    max-width: 50%;
  }

  @media screen and (max-width: 991px) {
    max-width: 100%;
  }

  &-block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-gap: 40px;
    margin-bottom: 80px;

    @media screen and (max-width: 1366px) {
      grid-gap: 24px;
    }

    @media screen and (max-width: 991px) {
      grid-template-rows: repeat(3, auto);
      margin-bottom: 56px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }

    &__item {
      display: flex;
      flex-direction: column;

      &:nth-child(2) {
        justify-content: center;

        @media screen and (max-width: 991px) {
          grid-column: 1/2;
        }
      }

      &:nth-child(3) {

        @media screen and (max-width: 991px) {
          grid-column: 1/2;
        }
      }

      &._contacts {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 24px;
        padding: 24px 48px;
        border: 1px solid $palette-light;
        border-radius: 32px;

        @media screen and (max-width: 1365px) {
          padding: 24px 38px;
        }

        @media screen and (max-width: 1199px) {
          padding: 20px 24px;
        }

        @media screen and (max-width: 991px) {
          max-width: 195px;
          padding: 15px;
          border-radius: 16px;
        }

        @media screen and (max-width: 575px) {
          display: grid;
          max-width: 100%;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-row-gap: 16px;
          grid-column-gap: 5px;
        }

        @media screen and (max-width: 374px) {
          grid-template-columns: auto;
        }
      }

      &-title {
        font-family: $font-additional;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        text-transform: uppercase;
        margin-bottom: 16px;

        @media screen and (max-width: 1199px) {
          font-size: 20px;
        }

        @media screen and (max-width: 767px) {
          margin-bottom: 8px;
        }
      }

      &-text {
        font-family: $font-main;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        @media screen and (max-width: 991px) {
          font-size: 14px;
        }
      }
    }
  }

  &-map {
    height: 316px;
    width: 100%;
    background: #ffffff;
    border-radius: 32px;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    @media screen and (max-width: 1250px) {
      margin-bottom: 50px;
    }

    @media screen and (max-width: 991px) {
      height: 240px;
    }
  }
}
</style>

<style lang="scss">
.contacts-block__item._contacts .contacts__item {
  font-family: $font-main;
  font-size: 17px;
  font-weight: 400;
  line-height: 18px;

  @media screen and (max-width: 1199px) {
    font-size: 16px;
  }

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }

  @media screen and (max-width: 575px) {
    &:nth-child(1) {
      grid-column: 1/3;
    }

    &:nth-child(2) {
      grid-column: 1/2;
    }
  }

  @media screen and (max-width: 374px) {
    &:nth-child(1) {
      grid-column: auto;
    }

    &:nth-child(2) {
      grid-column: auto;
    }
  }

  &-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;

    @media screen and (max-width: 991px) {
      width: 16px;
      height: 16px;
    }
  }
}
</style>