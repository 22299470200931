<template>
	<div class="auction _history">
		<div class="auction-content" v-if="errorText === ''">
			<div class="auction-content__filter">
				<TableFilter :names="filter" :items="lots" :history="true" :auctions="auctions" :current="current" @apply="applyFilter" @auctionSelect="getAuction" :key="componentKey"/>
			</div>
			<div class="auction-content__table">
				<TableAuction :columns="template" :items="lots" :colors="legend" :bid-size="bidSize" :bid-time="bidTime" :color-time="colorChangeTime" :exclude-sort="['sections']" :filter="setFilter" :limit="maxlimit" :deadline="deadline"/>
			</div>
		</div>
		<div class="auction-content" v-else>
			<div class="error-message">{{ errorText }}</div>
		</div>
	</div>
</template>

<script>
import AuctionData from '/static/data/auctionExpired.js';
import TableAuction from '@/components/Elements/TableAuction';
import TableFilter from '@/components/Elements/TableFilter';
import AppHandlers from '@/mixins/AppHandlers';

export default {
	name: 'AuctionHistory',
	components: {
		TableFilter,
		TableAuction
	},
	mixins: [
		AppHandlers
	],
	data: () => ({
		auctions: {},
		colorChangeTime: 3,
		legend: AuctionData.data.legend,
		template: AuctionData.data.template,
		lots: AuctionData.data.lots,
		filter: {
			section: 'Категория продукции',
			date: 'День отгрузки'
		},
		maxlimit: 3,
		errorLimit: false,
		bidSize: 5,
		bidTime: 120,
		deadline: Number(Math.floor(Date.now() / 1000) + 200),
		start: Number(Math.floor(Date.now() / 1000)),
		setFilter: {},
		current: '0',
		componentKey: 0,
		errorText: ''
	}),
	async mounted() {
		this.$root.showPreloader();
		let auctionList = {};
		let response = await this.getData('/get_auctions');
		if (response.title === 'An error occurred') {
			this.errorText = response.detail;
		} else {
			response.items.forEach(function (item) {
				auctionList['item_' + item.id] = item.name;
			});
		}
		this.auctions = auctionList;
		this.current = Object.keys(this.auctions)[0];
		await this.getAuction(this.current);
		this.componentKey++;
		this.$root.hidePreloader();
	},
	methods: {
		applyFilter($event) {
			this.setFilter = $event;
		},
		async getAuction(item) {
			this.$root.showPreloader();
			this.current = item;
			console.log(item);
			if (item) {
				let response = await this.getData('/get_auctions/' + item.split('_')[1]);
				console.log(response);
				if (response.title === 'An error occurred') {
					this.errorText = response.detail;
					this.lots = {};
				} else {
					this.lots = response.lots;
				}
				this.componentKey++;
			} else {
				this.lots = {};
			}
			this.$root.hidePreloader();
		}
	}
};
</script>

<style scoped lang="scss">
.auction {
  &-content {
    display: grid;
    grid-template-columns: 232px 1fr;
    grid-gap: 24px;

    @media screen and (max-width: 1365px) {
      grid-template-columns: 167px 1fr;
    }

    @media screen and (max-width: 1199px) {
      grid-template-columns: 1fr;
    }
  }
}
</style>

<style lang="scss">
._history table tr._notavailable {
  & td > * {
    opacity: 1;
  }
}

._history ._notavailable .mobile-grid__row-cell > * {
  opacity: 1;
}
</style>