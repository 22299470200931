<template>
  <router-view />
</template>

<script>
export default {
  name: 'ProfileIndex'
};
</script>

<style lang="scss">
.profile {
  &-head {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;

    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }

    @media screen and (max-width: 767px) {
      flex-direction: row;
      align-items: flex-end;
    }

    &._process {
      justify-content: space-between;

      @media screen and (max-width: 575px) {
        flex-direction: column;
        align-items: flex-end;
      }

      &._user {
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          flex-direction: row;

          & .process__select {
            max-width: 325px;
            order: unset;
            margin-top: 0;
          }

          & .profile-head__navigation {
            width: auto;
          }
        }
      }
    }

    &__navigation {
      display: flex;

      @media screen and (max-width: 1199px) {
        justify-content: flex-end;
      }

      @media screen and (max-width: 991px) {
        width: 100%;
        justify-content: space-between;
      }

      &-item {
        margin-left: 32px;

        @media screen and (max-width: 1365px) {
          margin-left: 18px;
          font-size: 14px!important;
        }

        @media screen and (max-width: 991px) {
          margin: 0;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  &-button {
    margin: 40px 0 35px;
  }
}
</style>