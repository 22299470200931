let data = {
	sections: {
		template: {
			id: 'ID пользователя',
			shortname: 'Краткое наименование организации',
			sections: 'Интересующие типы продукции'
		},
		items: [
			{
				id: '123',
				shortname: 'Краткое наименование организации',
				sections: 'Полутуши свиные<br/>Индустриальные зам<br/>Полутуши свиные<br/>Индустриальные продукты'
			},
			{
				id: '123',
				shortname: 'Краткое наименование организации',
				sections: 'Субпродукты свиные в ИУ<br/>Индустриальная охл'
			},
			{
				id: '123',
				shortname: 'Краткое наименование организации',
				sections: 'Полутуши свиные<br/>Индустриальные зам'
			},
			{
				id: '123',
				shortname: 'Краткое наименование организации',
				sections: 'Субпродукты свиные в ИУ<br/>Индустриальная охл<br/>'
			}
		]
	},
	activity: {
		template: {
			id: 'ID пользователя',
			shortname: 'Краткое наименование организации',
			createDate: 'Дата и время создания пользователя',
			authDate: 'Дата и время последней авторизации пользователя',
			bidDate: 'Дата и время последней ставки пользователя',
			winDate: 'Дата и время последней победы пользователя в торгах',
			loseStreak: 'Количество дней без побед',
			loseStreakBet: 'Общее число ставок пользователя за период дней без побед',
			currentStatus: 'Текущий статус активности пользователя'
		},
		items: [
			{
				id: '123',
				shortname: 'Краткое наименование организации',
				createDate: '12.07.2022 14:25:17',
				authDate: '12.07.2022 14:25:17',
				bidDate: '12.07.2022 14:25:17',
				winDate: '12.07.2022 14:25:17',
				loseStreak: '2',
				loseStreakBet: '16',
				currentStatus: 'Да'
			},
			{
				id: '123',
				shortname: 'Краткое наименование организации',
				createDate: '12.07.2022 14:25:17',
				authDate: '12.07.2022 14:25:17',
				bidDate: '12.07.2022 14:25:17',
				winDate: '12.07.2022 14:25:17',
				loseStreak: '14',
				loseStreakBet: '2',
				currentStatus: 'Нет'
			}
		]
	},
	process: {
		template: {
			articul: 'Артикул лота',
			baseprice: 'Базовая цена лота, руб./кг',
			bidSize: 'Размер ставки за лот, руб./кг',
			bidDate: 'Дата и время создания ставки',
			shortName: 'Краткое наименование организации',
			status: 'Статус доступности лота'
		},
		items: [
			{
				id: 1234567,
				name: 'Субпродукты свиные в ИУ 15.11.2022 11:30:00 - 13:46:00',
				data: [
					{
						name: 'Торг',
						value: '(1234567) Субпродукты свиные в ИУ 15.11.2022 11:30:00 - 13:46:00'
					},
					{
						name: 'Общее число лотов в торге',
						value: 125
					},
					{
						name: 'Число забронированных лотов в торге',
						value: 117
					},
					{
						name: 'Число лотов в торге без ставок',
						value: 8
					},
					{
						name: 'Количество авторизованных пользователей',
						value: 46
					},
					{
						name: 'Количество пользователей, делающих ставки',
						value: 42
					},
					{
						name: 'Количество пользователей, выигравших лот',
						value: 39
					},
					{
						name: 'Оставшееся время до окончания торга',
						value: '00:15:28'
					}
				],
				items: [
					{
						articul: '1234567890',
						baseprice: '115.8',
						bidSize: '115.7',
						bidDate: '12.07.2022 14:25:17',
						shortName: 'Краткое наименование организации',
						status: 'Забронирован'
					},
					{
						articul: '1234567890',
						baseprice: '115.8',
						bidSize: '115.8',
						bidDate: '12.07.2022 14:25:17',
						shortName: 'Краткое наименование организации',
						status: 'Забронирован'
					}
				]
			}
		]
	}
};

export default {
	data
};