<template>
	<div class="main" @mousemove="checkCursorPosition" :class="[{_index: isMain}, {_isSafari: isIos()}]" :style="bgCss">
		<div class="main-sfariBg" :style="bgCss" v-if="isIos()"></div>
		<HeaderBlock :class="!classContent ? '_inner' : ''" :shadow="isMain" @logout="isAutorized"/>
		<div class="main-body" :class="{_index: isMain}">
			<transition name="fade">
				<PreLoader size="medium" v-if="loading"/>
			</transition>
			<div class="content" :class="{_index: (isMain || isBg)}">
				<component :is="layout" :class="classContent">
					<router-view/>
				</component>
			</div>
		</div>
		<FooterBlock :shadow="isMain" :class="{_index: (isMain || isBg)}"/>
		<ModalWindow title="Авторизация" ref="auth">
			<AuthForm @auth="isAutorized"/>
		</ModalWindow>
		<ModalWindow ref="remind">
			<RemindForm/>
		</ModalWindow>
		<ModalWindow ref="register" :class="registerClass">
			<RegisterForm @changeStep="changeStep"/>
		</ModalWindow>
	</div>
</template>

<script>
import HeaderBlock from '@/components/TemplateBlocks/HeaderBlock.vue';
import FooterBlock from '@/components/TemplateBlocks/FooterBlock.vue';
import ParalaxHandler from '@/mixins/ParalaxHandler';
import InnerLayout from '@/layouts/InnerLayout';
import ProfileLayout from '@/layouts/ProfileLayout';
import IndexLayout from '@/layouts/IndexLayout';
import ModalWindow from '@/components/MoadalWindow';
import AuthForm from '@/components/Auth/AuthForm';
import RemindForm from '@/components/Auth/RemindForm';
import RegisterForm from '@/components/Auth/RegisterForm';
import PreLoader from '@/components/Elements/PreLoader';
import {socket, socketEmit} from '@/helpers/Socket';
import AppHandlers from '@/mixins/AppHandlers';

export default {
	components: {
		RegisterForm,
		RemindForm,
		AuthForm,
		HeaderBlock,
		FooterBlock,
		InnerLayout,
		ProfileLayout,
		IndexLayout,
		ModalWindow,
		PreLoader
	},
	mixins: [
		ParalaxHandler,
		AppHandlers
	],
	data: () => ({
		screenWidth: document.documentElement.clientWidth,
		autorized: false,
		registerClass: '_register',
		loading: false,
		contacts: []
	}),
	mounted() {
		this.emitAuth();

		console.log(JSON.parse(localStorage.getItem('user')));

		socket.on('logout', () => {
			this.$store.dispatch('auth/logout');
			this.isAutorized(false);
			this.$router.push('/');
		});

		let urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get('password')) {
			this.showRemind();
		}
		if (this.isAuth) {
			this.autorized = true;
			this.$refs.auth.show = false;
		}

		this.getContacts();
	},
	watch: {
		'$route.path'() {
			this.hidePreloader();
		},
		isAuth() {
			this.emitAuth();
		}
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAuth() {
			return this.$store.getters['auth/isAuth'];
		},
		layout() {
			return this.$route.meta.layout || 'InnerLayout';
		},
		classContent() {
			return this.$route.meta.color || false;
		},
		isMain() {
			let main = false;
			if (this.$route.meta.main === true) {
				main = true;
			}
			return main;
		},
		bgCss() {
			let bgImg = '';
			if (document.documentElement.clientWidth < 992) {
				if (this.$route.meta.mobile_bg)
					bgImg = this.$route.meta.mobile_bg;
				else if (this.$route.meta.background)
					bgImg = this.$route.meta.background;
			} else {
				if (this.$route.meta.background)
					bgImg = this.$route.meta.background;
				else
					bgImg = '/images/default_bg.jpg';
			}
			return {
				backgroundImage: 'url(' + bgImg + ')'
			};
		},
		isBg() {
			let className = '';
			if (this.$route.meta.color === '_dark') {
				className = '_transparent';
			}
			return className;
		}
	},
	methods: {
		emitAuth() {
			if (this.isAuth) {
				socketEmit('userConnect', this.currentUser);
			}
		},
		showPreloader() {
			this.loading = true;
		},
		hidePreloader() {
			this.loading = false;
		},
		hidePopup() {
			this.$refs.auth.show = false;
			this.$refs.remind.show = false;
			this.$refs.register.show = false;
		},
		showAuth() {
			this.hidePopup();
			this.$refs.auth.show = true;
		},
		showRemind() {
			this.hidePopup();
			this.$refs.remind.show = true;
		},
		showRegister() {
			this.hidePopup();
			this.$refs.register.show = true;
		},
		isAutorized(data) {
			this.autorized = data;
			this.$refs.auth.show = false;
		},
		changeStep(data) {
			if (data === 4) {
				this.registerClass = '_success';
			} else {
				this.registerClass = '_register';
			}
		},
		isIos() {
			return /iPhone|Mac OS X|iPad|iPod/i.test(navigator.userAgent);
		},

		getContactsByPage() {
			return this.contacts;
		},

		async getContacts() {
			try {
				let response = await this.getDataWithoutToken('/company_datas');
				this.$mitt.emit('update-contacts', response);
				this.contacts = response;
			} catch (e) {
				//this.content = e.response.data['hydra:description'];
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 1365px) {
    background-attachment: fixed;
  }

  @media screen and (max-width: 991px) {
    background-size: 100%;
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: unset;
  }

  @media screen and (max-width: 767px) {
    grid-template-rows: 50px auto 60px;
  }

  &._isSafari {
    @media screen and (min-width: 992px) {
      background-image: none !important;
    }
  }

  &-sfariBg {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    background-position: center;
    background-size: cover;

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &._index {
    background-size: cover;
    overflow: hidden;
  }

  &-body {
    position: relative;
    display: grid;
    flex-grow: 1;
    overflow: hidden;

    &._index {
      overflow: unset;
    }
  }
}

.content {
  background: #ffffff;

  &._index {
    background: none;
  }
}

</style>
