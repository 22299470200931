<template>
  <div class="header" :class="{_shadow: shadow}">
    <div class="header-wrapper">
      <div class="container">
        <div class="header-container _left">
          <HeaderMenu v-if="mediaQuery({MinWidth: 576})"/>
        </div>
        <div class="header-container header-contacts _right">
          <ContactsList v-if="mediaQuery({MinWidth: 992})" :hover="true"/>
          <AuthBlock @logout="isLogout" v-if="mediaQuery({MinWidth: 992})"/>

          <MobileMenu v-if="mediaQuery({MinWidth: 768, MaxWidth: 991})" side="top" class="mobile-burger">
            <div class="mobile-wrapper">
              <ContactsList/>
              <AuthBlock @logout="isLogout"/>
            </div>
            <div class="mobile-wrapper">
              <transition name="slide-fade">
                <ProfileMenu v-if="this.$root.autorized"/>
              </transition>
            </div>
          </MobileMenu>

          <MobileMenu v-if="mediaQuery({MaxWidth: 767})" side="right" class="mobile-burger">
            <HeaderMenu v-if="mediaQuery({MaxWidth: 575})"/>
            <transition name="slide-fade">
              <ProfileMenu v-if="this.$root.autorized"/>
            </transition>
            <ContactsList/>
            <AuthBlock @logout="isLogout"/>
          </MobileMenu>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <div class="header-wrapper _bottom" v-if="mediaQuery({MinWidth: 992}) && this.$root.autorized">
        <div class="container">
          <ProfileMenu/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import templateData from '/static/data/template.js';
import ContactsList from '@/components/ContactsList';
import AuthBlock from '@/components/Auth/AuthBlock';
import MediaHandlers from '@/mixins/MediaHandlers';
import MobileMenu from '@/components/MobileMenu';
import HeaderMenu from '@/components/HeaderMenu';
import ProfileMenu from '@/components/ProfileMenu';

export default {
  name: 'HeaderBlock',
  props: {
    shadow: Boolean
  },
  emits: [
    'logout'
  ],
  components: {
    ProfileMenu,
    HeaderMenu,
    MobileMenu,
    ContactsList,
    AuthBlock
  },
  mixins: [
    MediaHandlers
  ],
  data: () => ({
    menu: templateData.data.header.menu
  }),
  methods: {
    isLogout(data) {
      this.$store.dispatch('auth/logout');
      this.$emit('logout', data);
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: grid;
  grid-template-rows: auto auto;

  &._inner {
    background: url("/public/images/header_bg.jpg");
    background-size: cover;
  }

  &._shadow {
    background: linear-gradient(0deg, transparent, $palette-dark);
  }

  @media screen and (max-width: 767px) {
    grid-template-rows: 1fr;
  }

  &-wrapper {
    & .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      color: $palette-light;
      height: 50px;

      @media screen and (max-width: 1365px) {
        grid-template-columns: 4fr 5fr;
      }

      @media screen and (max-width: 1199px) {
        grid-template-columns: 5fr 4fr;
      }

      @media screen and (max-width: 991px) {
        grid-template-columns: 5fr 1fr;
      }
    }

    &._bottom {

      z-index: 1;

      & .template-content {
        display: flex;
        overflow: hidden;
      }

      ._inner & {
        background: $palette-table;
      }

      & .container {
        display: flex;
      }
    }
  }


  &-container {
    display: flex;

    &._right {
      display: flex;
      justify-content: flex-end;


      @media screen and (max-width: 991px) {
        justify-content: flex-end;
      }
    }
  }
}

.mobile-wrapper {
  display: flex;
  grid-gap: 20px;

  &:first-child {
    padding: 4px;
  }

  &:last-child {
    border-top: 1px solid #C0C0C0;
  }
}
</style>

<style lang="scss">
.header-contacts._right > * {
  margin-left: 24px;

  &:first-child {
    margin-left: 0;
  }
}

.header .contacts {
  &__item {
    display: flex;
    align-items: center;
    color: $palette-light;

    @media screen and (max-width: 991px) {
      color: $palette-dark;
    }

    &-icon {
      width: 26px;
      height: 28px;
      margin-right: 12px;
      stroke: $palette-light;

      @media screen and (max-width: 991px) {
        stroke: $palette-dark;
        height: 20px;
      }
    }

    &._phone {
      @media screen and (max-width: 767px) {
        margin-top: 10px;
      }
    }

    & span {
      padding: 8px 10px;

      @media screen and (max-width: 1199px) {
        display: none;
      }

      @media screen and (max-width: 991px) {
        padding: 8px 5px;
        display: block;
        font-size: 14px;
      }
    }
  }
}
</style>