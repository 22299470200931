<template>
  <div class="index-content">
    <div class="index-content__item">
      <div class="index-about">
        <div class="index-about__title">{{ title }}</div>
        <div class="index-about__subtitle">{{ subtitle }}</div>
        <div class="index-about__text" v-html="text"></div>
        <div class="index-about__buttons" v-if="!this.$root.autorized">
          <ButtonItem text="Зарегистрироваться" color="red" @click="this.$root.showRegister"/>
          <ButtonItem text="Войти" class="_shadow" @click="this.$root.showAuth"/>
        </div>
        <div class="index-about__buttons" v-else>
          <ButtonItem text="Личный кабинет" color="red" link="/profile"/>
          <ButtonItem text="Выйти" class="_shadow" @click="isLogout"/>
        </div>
      </div>
    </div>
    <div class="index-content__item">
      <IndexLogo/>
    </div>
  </div>
</template>

<script>
import templateData from '/static/data/template.js';
import IndexLogo from '@/components/TemplateBlocks/IndexLogo';
import ButtonItem from '@/components/Elements/ButtonItem';

export default {
  name: 'IndexPage',
  components: {
    ButtonItem,
    IndexLogo
  },
  data() {
    return {
      title: templateData.data.index.title,
      subtitle: templateData.data.index.subtitle,
      text: templateData.data.index.text
    };
  },
  methods: {
		isLogout() {
			this.$store.dispatch('auth/logout');
			this.$root.isAutorized(false);
			this.$router.push('/');
		}
  }
};
</script>

<style lang="scss">
.index {
  &-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: $palette-light;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1512px;
    margin: 0 auto;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
    @media screen and (max-width: 767px) {
      grid-template-rows: 4fr 3fr;
    }

    @media screen and (max-width: 575px) {
      grid-template-rows: 5fr 4fr;
    }

    &__item {
      display: grid;
      align-items: center;
      height: 100%;

      @media screen and (max-width: 991px) {
        align-items: start;
      }
    }
  }

  &-about {
    text-align: left;
    max-width: 100%;
    z-index: 5;

    @media screen and (max-width: 1600px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 1365px) {
      padding: 0 25px;
    }
    @media screen and (max-width: 991px) {
      max-width: unset;
      margin-top: 70px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 0;
      padding: 0 15px;
    }

    & a {
      color: $palette-light;
    }

    &__title {
      font-family: $font-additional;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      text-transform: uppercase;
      margin-bottom: 24px;

      @media screen and (max-width: 1199px) {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 15px;
      }

      @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 32px;
        margin-top: 20px;
        margin-bottom: 15px;
      }

      @media screen and (max-width: 575px) {
        margin-bottom: 8px;
      }

      @media screen and (max-width: 374px) {
        margin-top: 0;
      }
    }

    &__subtitle {
      font-family: $font-additional;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;

      @media screen and (max-width: 1199px) {
        font-size: 20px;
        line-height: 24px;
      }

      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $palette-gray;
      margin-bottom: 55px;

      @media screen and (max-width: 1199px) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 40px;
      }

      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
      }

      @media screen and (max-width: 575px) {
        margin-bottom: 24px;
      }

      & a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      & p {
        margin-bottom: 12px;

        @media screen and (max-width: 767px) {
          margin-bottom: 8px;
        }
      }
    }

    &__buttons {
      display: flex;
    }
  }
}
</style>

<style lang="scss">
  .index-about__buttons > * {
    margin-right: 30px;
  }
</style>
