<template>
  <footer class="footer" :class="{_shadow: shadow}">
    <div class="footer-wrapper container">
      <div class="footer-item _sitemap">
        <router-link class="footer-item__link _bold" to="/sitemap">Карта сайта</router-link>
      </div>
      <div class="footer-item _policy">
        <router-link class="footer-item__link" to="/policy">Политика конфиденциальности</router-link>
      </div>
      <div class="footer-item _copiright">
        <span class="footer-item__copyright">© {{ currentYear }} - Компания «Коралл»</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterBlock',
  props: {
    shadow: Boolean
  },
  computed: {
    currentYear() {
      let today = new Date();
      return today.getFullYear();
    }
  }
};
</script>

<style lang="scss">
footer {
  padding: 20px 0;
  height: 60px;
  background: $palette-light;

  &._index  {
    background: none;
    z-index: 1;
  }

  &._shadow {
    background: linear-gradient(180deg, transparent, $palette-dark);
  }

  @media screen and (max-width: 575px) {
    padding: 12px;
  }
}

.footer-wrapper {
  display: flex;
  z-index: 5;
  color: $palette-dark;

  ._index & {
    color: $palette-light;
  }

  @media screen and (max-width: 575px) {
    display: grid;
    grid-template-columns: 3fr 4fr;
    grid-template-rows: 15px 15px;

    &.container {
      padding: 0;
    }

    & ._sitemap {
        grid-column-start: 1;
        grid-row-start: 1;
        grid-row-end: 3;
        padding: 5px 0;
    }

    & ._policy {
        grid-column-start: 2;
        grid-row-start: 1;
        text-align: right;
    }

    & ._copiright {
        grid-column-start: 2;
        grid-row-start: 2;
        text-align: right;
    }
  }
}

.footer-item {
  margin-right: 20px;

  @media screen and (max-width: 575px) {
    margin: 0;
  }

  &__link {
    font-family: $font-additional;
    font-size: 14px;
    line-height: 16px;
    color: $palette-dark;

    ._index & {
      color: $palette-light;
    }

    @media screen and (max-width: 991px) {
      font-size: 12px;
      line-height: 24px;
    }

    @media screen and (max-width: 575px) {
      line-height: 16px;
    }

    &._bold {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__copyright {
    font-family: $font-additional;
    font-size: 14px;
    line-height: 16px;
    color: $palette-dark;

    @media screen and (max-width: 575px) {
      font-size: 12px;
    }

    ._index & {
      color: $palette-light;
    }
  }
}
</style>