<template>
  <div class="policy">
    <p>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую аукционная площадка (далее – сайт), расположенная на доменном имени sales.coral-meat.ru, может получить о Пользователе во время использования аукционной площадки.</p>
    <p>Обращаем Ваше внимание на то, что данная аукционная площадка носит исключительно информационный характер и ни при каких условиях информационные материалы и цены, размещенные на аукционной площадке, не являются публичной офертой, определяемой положениями Статьи 437 Гражданского кодекса РФ.</p>

    <div class="policy-render" v-html="listRender(policyData)"></div>

  </div>
</template>

<script>
import policyData from '/static/data/policy.js';

export default {
  name: 'PolicyPage',
  data: () => ({
    policyData: policyData.data
  }),
  methods: {
    listRender(data, parentIndex = false) {
      let result = '';
      if (data) {
        result += '<div class="policy-list">';
        data.forEach((element, index) => {
          let content = '',
              countIndex = index + 1,
              elementIndex = '';

          if (parentIndex) {
            elementIndex += parentIndex;
          }
          elementIndex += countIndex + '.';

          content += '<div class="policy-list__item">';
          content += '<div class="policy-list__item-content"><div>' + elementIndex + '</div><div>' + element.text + '</div></div>';
          if (element.items) {
            content += this.listRender(element.items, elementIndex);
          }
          content += '</div>';
          result += content;
        });
        result += '</div>';
      }
      return result;
    }
  }
};
</script>

<style lang="scss">
.policy {
  &-list {
    padding-left: 30px;
    margin-bottom: 20px;
    &__item {
      &-content {
        display: flex;
        margin-bottom: 10px;

        & {
          font-family: $font-main;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          @media screen and (max-width: 991px) {
            font-size: 14px;
          }

          & div:first-child {
            font-weight: 600;
            margin-right: 10px;
          }
        }
      }
    }
  }

  &-render {
    & > .policy-list {
      padding: 0;
      & > .policy-list__item {
        & > .policy-list__item-content {
          font-family: $font-additional;
          font-size: 20px;
          font-weight: 700;
          line-height: 32px;
          margin-bottom: 20px;

          @media screen and (max-width: 991px) {
            font-size: 16px;
            margin-bottom: 10px;
          }

          & div {
            &:first-child {
              font-family: $font-main;
              font-size: 20px;
              font-weight: 700;
              line-height: 32px;

              @media screen and (max-width: 991px) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
</style>