import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";

import '@/styles/fonts.css';
import '@/styles/main.scss';

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

import VueCookies from 'vue-cookies'

import setupInterceptors from './services/setupInterceptors';
setupInterceptors(store);

import mitt from 'mitt';
const emitter = mitt();

let Vue = createApp(App);

Vue.config.globalProperties.$mitt = emitter;

Vue.use(router);
Vue.use(store);
Vue.use(PerfectScrollbar);
Vue.use(VueCookies, { expires: '7d'});
Vue.mount('#app');
//Vue.config.devtools = true;
