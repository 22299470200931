<template>
  <div class="container _inner">
    <BreadcrumbBlock v-if="!is404"/>
    <h1 class="h1" v-if="!is404">{{ title }}</h1>
    <div class="inner-content">
      <slot/>
    </div>
  </div>
</template>

<script>
import BreadcrumbBlock from '@/components/TemplateBlocks/BreadcrumbBlock';

export default {
  name: 'InnerLayout',
  components: {
    BreadcrumbBlock
  },
  computed: {
    title() {
      return this.$route.name;
    },
    is404() {
      let is404 = false;
      if (this.$route.name === '404') {
        is404 = true;
      }
      return is404;
    }
  }
};
</script>

<style scoped lang="scss">
  .h1 {
    font-family: $font-additional;
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 25px;

    @media screen and (max-width: 1365px) {
      margin-bottom: 20px;
    }

    @media screen and (max-width: 1199px) {
      font-size: 28px;
    }

    @media screen and (max-width: 991px) {
      font-size: 24px;
    }

    ._dark & {
      @media screen and (max-width: 575px) {
        font-size: 24px;
        margin-bottom: 70px;
      }
    }
  }

  .container {
    &._dark {
      color: $palette-light;
    }
  }
</style>