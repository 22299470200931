<template>
  <div class="auth-wrapper">
    <SvgIcon name="user" class="auth-icon"/>
    <div class="auth-block" v-if="this.$root.autorized">
      <div class="auth-block__item _hovered">
        <router-link class="auth-block__item-link" to="/profile">Личный кабинет</router-link>
      </div>
      <div class="auth-block__divider">|</div>
      <div class="auth-block__item _hovered">
        <router-link class="auth-block__item-link" to="#" @click="logout">Выход</router-link>
      </div>
    </div>
    <div class="auth-block" v-else>
      <div class="auth-block__item _hovered">
        <a class="auth-block__item-link" href="#" @click="this.$root.showRegister">Регистрация</a>
      </div>
      <div class="auth-block__divider">|</div>
      <div class="auth-block__item _hovered">
        <a class="auth-block__item-link" href="#" @click="this.$root.showAuth">Вход</a>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
import AppHandlers from '@/mixins/AppHandlers';

export default {
  name: 'AuthBlock',
  components: {
    SvgIcon
  },
  mixins: [
    AppHandlers
  ],
  emits: [
    'logout'
  ],
  data() {
    return {
      isAuth: true
    };
  },
  methods: {
    logout() {
      this.$router.push('/');
      this.isAuth = false;
      this.event();
    },
    event() {
      this.$emit('logout', this.isAuth);
    }

  }
};
</script>

<style lang="scss" scoped>
.auth-icon {
  width: 26px;
  height: 28px;
  margin-right: 5px;
  stroke: $palette-light;

  @media screen and (max-width: 991px) {
    stroke: $palette-dark;
    height: 20px;
  }
}

._hovered {
  padding: 5px;
  border-radius: 50px;
  transition: .3s;

  @media screen and (max-width: 991px) {
    padding: 8px 5px;
  }

  &:hover {
    background: $palette-light_10;
  }
}

.auth-wrapper {
  display: flex;
  align-items: center;
  color: $palette-light;

  @media screen and (max-width: 991px) {
    color: $palette-dark;
  }

  & span {
    @media screen and (max-width: 1199px) {
      display: none;
    }

    @media screen and (max-width: 991px) {
      display: block;
      font-size: 14px;
    }
  }
}

.auth-block {
  display: flex;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;

    &-link {
      color: $palette-light;

      @media screen and (max-width: 991px) {
        color: $palette-dark;
        font-size: 14px;
      }
    }
  }

  &__divider {
    margin: 8px 2px;
  }
}
</style>