export default {
	data() {
		return {
			screenWidth: 0
		};
	},
	mounted() {
		window.addEventListener('resize', this.setScreenWidth);
		this.setScreenWidth();
	},
	unmounted() {
		window.removeEventListener('resize', this.setScreenWidth);
	},
	methods: {
		setScreenWidth() {
			this.screenWidth = document.documentElement.clientWidth;
		},
		mediaQuery(props) {
			let result = false;
			if (props.MaxWidth && props.MinWidth) {
				if ((this.screenWidth >= props.MinWidth) && (this.screenWidth <= props.MaxWidth)) result = true;
			} else {
				if (props.MaxWidth) {
					if (this.screenWidth <= props.MaxWidth) result = true;
				}
				if (props.MinWidth) {
					if (this.screenWidth >= props.MinWidth) result = true;
				}
			}
			return result;
		}
	}
};