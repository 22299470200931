<template>
  <div class="notfound">
    <img src="/images/404.png" class="notfound-img" alt="NotFound">
    <div class="notfound-text">
      Неправильно набран адрес или такой страницы больше не существует
    </div>
    <div class="notfound-control">
      <ButtonItem text="Назад" @click="this.$router.go(-1)"/>
      <ButtonItem text="На главную" link="/" color="red"/>
    </div>
  </div>
</template>

<script>
import ButtonItem from '@/components/Elements/ButtonItem';

export default {
  name: 'NotFound',
  components: {
    ButtonItem
  }
};
</script>

<style scoped lang="scss">
.notfound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100%;
  color: $palette-light;

  &-img {
    display: block;
    max-width: 100%;
    margin-bottom: 75px;

    @media screen and (max-width: 1365px) {
      max-width: 460px;
      margin-bottom: 50px;
    }

    @media screen and (max-width: 767px) {
      max-width: 265px;
      margin-bottom: 25px;
    }
  }

  &-text {
    font-family: $font-additional;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 30px;
    text-align: center;

    @media screen and (max-width: 1365px) {
      font-size: 20px;
      line-height: 28px;
    }

    @media screen and (max-width: 1199px) {
      font-size: 16px;
      line-height: 24px;
    }

    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &-control {
    display: flex;
  }
}
</style>

<style lang="scss">
.notfound-control > * {
  margin: 0 15px;
}
</style>