let data = {
	contragent: {
		title: 'Контрагент',
		items: {
			inn: {
				name: 'ИНН',
				value: '1234567890'
			},
			shortname: {
				name: 'Краткое наименование организации',
				value: 'Краткое наименование организации'
			},
			regadress: {
				name: 'Адрес регистрации',
				value: '123456, г. Название, ул. Название, д. 1'
			},
			ogrn: {
				name: 'ОГРН/ОГРНИП',
				value: '1234567890123 123456789012345'
			},
			fullname: {
				name: 'Полное наименование организации/ИП',
				value: 'Полное наименование организации/ИП'
			},
			postadress: {
				name: 'Почтовый адрес',
				value: 'info@company.ru'
			}
		}
	},
	contacts: {
		title: 'Контактное лицо',
		items: [
			{
				position: {
					name: 'Должность контактного лица',
					value: 'Должность'
				},
				name: {
					name: 'Имя контактного лица',
					value: 'Иван'
				},
				surname: {
					name: 'Фамилия контактного лица',
					value: 'Петров'
				},
				email: {
					name: 'E-mail контактного лица',
					value: 'i.petrov@company.ru'
				},
				mobilephone: {
					name: 'Мобильный телефон контактного лица',
					value: '8 (999) 999-99-99'
				},
				staticphone: {
					name: 'Стационарный телефон контактного лица',
					value: '8 (99999) 99-999'
				}
			}
		]
	},
	limits: {
		title: 'Пользователь',
		template: {
			name: 'Доступные для участия в торгах типы продукции',
			value: 'Максимальный лимит лотов'
		},
		items: [
			{
				name: 'Полутуши свиные',
				value: 18
			},
			{
				name: 'Индустриальные зам',
				value: 8
			},
			{
				name: 'Индустриальные продукты',
				value: 11
			},
			{
				name: 'Субпродукты свиные в ИУ',
				value: 7
			},
			{
				name: 'Индустриальная охл',
				value: 13
			}
		]
	}
};

export default {
	data
};