let data = {
	legend: {
		lead: {
			title: 'Лидирую',
			color: '#00AE4D'
		},
		none: {
			title: 'Доступен',
			color: '#008FD3'
		},
		lose: {
			title: 'Уступаю',
			color: '#ED2C39'
		},
		complete: {
			title: 'Забронирован',
			color: '#D9D9D9'
		}
	},
	template: {
		articul: 'Артикул',
		date: 'Дата',
		name: 'Название',
		weigth: 'Вес',
		section: 'Категория продукции',
		timer: 'Таймер',
		bid: {
			name: 'Ставка',
			base: 'Базовая',
			current: 'Текущая',
			my: 'Моя'
		}
	},
	lots: [
		{
			articul: '1234567891',
			date: {
				day: 'Сб',
				value: '11.07.2022'
			},
			name: 'Лот_Название_1_вс1',
			weigth: '22345',
			section: 'Межсосковая часть свиных шкур зам. (монолит)',
			timer: Number(Math.floor(Date.now() / 1000)),
			bid: {
				base: 110.8,
				current: 111.8,
				my: 111.8
			},
			status: 'lead',
			available: false
		},
		{
			articul: '1234567892',
			date: {
				day: 'Вс',
				value: '12.07.2022'
			},
			name: 'Лот_Название_1_вс1',
			weigth: '22345',
			section: 'Сердце свиное зам. (блок)',
			timer: Number(Math.floor(Date.now() / 1000)),
			bid: {
				base: 110.8,
				current: 112.8,
				my: '-'
			},
			status: 'none',
			available: false
		},
		{
			articul: '1234567893',
			date: {
				day: 'Вс',
				value: '12.07.2022'
			},
			name: 'Лот_Название_1_вс1',
			weigth: '22345',
			section: 'Сердце свиное зам. (блок)',
			timer: Number(Math.floor(Date.now() / 1000)),
			bid: {
				base: 110.8,
				current: 115.8,
				my: '-'
			},
			status: 'none',
			available: false
		},
		{
			articul: '1234567894',
			date: {
				day: 'Вс',
				value: '12.07.2022'
			},
			name: 'Лот_Название_1_вс1',
			weigth: '22345',
			section: 'Щековина свиная зам. (монолит)',
			timer: Number(Math.floor(Date.now() / 1000)),
			bid: {
				base: 110.8,
				current: 115.8,
				my: '-'
			},
			status: 'none',
			available: false
		},
		{
			articul: '1234567895',
			date: {
				day: 'Вс',
				value: '12.07.2022'
			},
			name: 'Лот_Название_1_вс1',
			weigth: '22345',
			section: 'Жир-сырец свиной 1 гр. зам. (блок)',
			timer: Number(Math.floor(Date.now() / 1000)),
			bid: {
				base: 110.8,
				current: 115.8,
				my: '-'
			},
			status: 'none',
			available: false
		},
		{
			articul: '1234567896',
			date: {
				day: 'Сб',
				value: '11.07.2022'
			},
			name: 'Лот_Название_1_вс1',
			weigth: '22345',
			section: 'Легкие свиные зам. (блок)',
			timer: Number(Math.floor(Date.now() / 1000)),
			bid: {
				base: 110.8,
				current: 115.8,
				my: 115.8
			},
			status: 'lead',
			available: false
		},
		{
			articul: '1234567897',
			date: {
				day: 'Вс',
				value: '12.07.2022'
			},
			name: 'Лот_Название_1_вс1',
			weigth: '22345',
			section: 'Межсосковая часть свиных шкур зам. (монолит)',
			timer: Number(Math.floor(Date.now() / 1000)),
			bid: {
				base: 110.8,
				current: 115.8,
				my: '-'
			},
			status: 'none',
			available: false
		},
		{
			articul: '1234567898',
			date: {
				day: 'Вс',
				value: '12.07.2022'
			},
			name: 'Лот_Название_1_вс1',
			weigth: '22345',
			section: 'Диафрагма свиная зам. (блок)',
			timer: Number(Math.floor(Date.now() / 1000)),
			bid: {
				base: 110.8,
				current: 115.8,
				my: 111.8
			},
			status: 'lose',
			available: false
		}
	]
};

export default {
	data
};