<template>
	<div class="auction">
		<div class="auction-mobile__filter" v-if="mediaQuery({MaxWidth: 1199})">
			<TableFilter :names="filter" :items="lots" @apply="applyFilter" :key="componentKey"/>
		</div>
		<div class="auction-head">
			<div class="auction-head__legend">
				<div class="auction-head__legend-item" v-for="(item, key) in legend" :key="key">
					<div class="auction-head__legend-item__title">{{ item.title }}</div>
					<div class="auction-head__legend-item__color" :style="{backgroundColor: item.color}"></div>
				</div>
			</div>
			<transition name="slide-fade">
				<div class="auction-head__error" v-if="errorText">
					{{ errorText }}
				</div>
			</transition>
		</div>
		<div class="auction-content">
			<div class="auction-content__filter" v-if="mediaQuery({MinWidth: 1200})">
				<TableFilter :names="filter" :items="lots" @apply="applyFilter"/>
			</div>
			<div class="auction-content__table">
				<div>
					<TableAuction v-if="active" :columns="template" :items="lots" :colors="legend" :bid-size="bidSize" :bid-time="bidTime" :color-time="colorChangeTimeLot" :exclude-sort="['sections']" :filter="setFilter" :limit="maxlimit" :deadline="deadline" @expiredLimit="expiredLimit" @timesUp="timesUp" :key="componentKey"/>
					<div v-else class="auction-content__message">Нет активного аукциона</div>
				</div>
			</div>
		</div>
		<FixedTimer :deadline="deadline" :start="start" :color-time="colorChangeTimeGlobal" :key="[screenWidth, componentKey]"/>
	</div>
</template>

<script>
import AuctionData from '/static/data/auction.js';
import TableAuction from '@/components/Elements/TableAuction';
import TableFilter from '@/components/Elements/TableFilter';
import FixedTimer from '@/components/Elements/FixedTimer';
import MediaHandlers from '@/mixins/MediaHandlers';
import {socket, socketEmit} from '@/helpers/Socket';


export default {
	name: 'AuctionCurrent',
	components: {
		FixedTimer,
		TableFilter,
		TableAuction
	},
	mixins: [
		MediaHandlers
	],
	data: () => ({
		colorChangeTimeGlobal: 3,
		colorChangeTimeLot: 1,
		legend: AuctionData.data.legend,
		template: AuctionData.data.template,
		lots: [],
		filter: {
			section: 'Категория продукции',
			date: 'День отгрузки'
		},
		maxlimit: 3,
		errorText: false,
		bidSize: 5,
		bidTime: 60,
		deadline: '0',
		start: '0',
		setFilter: {},
		active: false,
		componentKey: 0
	}),
	mounted() {
		if (this.$store.getters['auth/isAuth']) {
			this.$root.showPreloader();
			socketEmit('get-auction');

			socket.on('get-auction', async (data) => {
				console.log('---Update---');
				await this.getAuction(data);
			});

			socket.on('my-rates', (data) => {
				this.getRates(data);
			});

			socket.on('lot-update', (data) => {
				this.lotUpdate(data);
			});

			socket.on('lot-update-error', (data) => {
				if (data.error) {
					this.setError(data.error);
				} else {
					this.lots.forEach((element) => {
						if (element['id'] === data['lotId']) {
							this.setError('Произошла ошибка при добавлении ставки');
						}
					});
				}
				this.$root.hidePreloader();
			});

			socket.on('update-page', () => {
				window.location.reload();
			});
			this.$root.hidePreloader();
		}
	},
	methods: {
		applyFilter($event) {
			this.setFilter = $event;
		},
		expiredLimit() {
			this.setError('Для следующей ставки, увеличьте число максимальных лимитов');
		},
		timesUp() {
			this.setError('Таймер лота не может превышать общее время торга');
		},
		setError($text) {
			this.errorText = $text;
			this.clearError();
		},
		clearError() {
			setTimeout(() => this.errorText = false, 5000);
		},
		getAuction(data) {
			if (data.error) {
				this.setError(data.error);
			} else {
				if (data.auction) {
					this.start = String(data.auction.startDate);
					this.deadline = String(data.auction.endDate);
					this.colorChangeTimeLot = data.auction.changeColorTimeLot;
					this.colorChangeTimeGlobal = data.auction.changeColorTimeAuction;
					this.bidSize = data.auction.priceStep;
					this.bidTime = data.auction.lotTimer;

					let newLots = [];
					if (data.auction.lots && data.auction.lots.length) {
						data.auction.lots.forEach(element => {
							let lot = {
								id: element['id'],
								articul: element['articul'],
								date: {
									day: element['shipmentDate']['day'],
									value: element['shipmentDate']['date']
								},
								name: element['name'],
								weigth: element['weigth'],
								section: element['productCategory']['name'],
								timer: String(element['timer']),
								bid: {
									base: element['basePrice'],
									current: element['currentRate'],
									my: '-'
								},
								status: 'none',
								available: data.auction.status === 'active'
							};

							newLots.push(lot);
						});

						this.lots = newLots;
						this.active = true;
					}
					this.componentKey++;
				}
			}
		},
		getRates(data) {
			if (data.error) {
				this.setError(data.error);
			} else {
				if (data) {
					this.lots.forEach((lot, index) => {
						this.lots[index]['status'] = data.lots[lot['id']]['status'];
						this.lots[index]['bid']['my'] = (data.lots[lot['id']] ? data.lots[lot['id']]['rate'] : '-');
					});
				}
			}
		},
		lotUpdate(data) {
			if (data.error) {
				this.setError(data.error);
			} else {
				this.lots.forEach((element, index) => {
					if (element['id'] === data['lotId']) {
						this.lots[index]['bid']['current'] = data['rate'];

						if (data['userId'] === this.$store.state.auth.user.id) {
							this.lots[index]['status'] = 'lead';
							this.lots[index]['bid']['my'] = data['rate'];
						} else if (this.lots[index]['bid']['my'] !== '-' && this.lots[index]['bid']['my'] > 0) {
							this.lots[index]['status'] = 'lose';
						} else {
							this.lots[index]['status'] = 'none';
						}

						this.lots[index]['timer'] = String(data['timer']);
						this.lots[index]['is_blocked'] = false;
					}
				});
			}
			this.$root.hidePreloader();
		}
	}
};
</script>

<style scoped lang="scss">
.auction {
  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media screen and (max-width: 991px) {
      height: 30px;
    }

    @media screen and (max-width: 767px) {
      height: auto;
      flex-direction: column;
    }

    &__legend {
      display: flex;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      &-item {
        width: 104px;
        margin-right: 24px;

        @media screen and (max-width: 991px) {
          width: 84px;
        }

        @media screen and (max-width: 575px) {
          width: auto;
        }

        &:last-child {
          margin-right: 0;
        }

        &__title {
          font-family: $font-main;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          margin-bottom: 4px;

          @media screen and (max-width: 991px) {
            font-size: 12px;
          }
        }

        &__color {
          height: 4px;
          width: 100%;
          border-radius: 2px;
        }
      }
    }

    &__error {
      font-family: $font-additional;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $palette-red;

      @media screen and (max-width: 1199px) {
        font-size: 14px;
        line-height: 20px;
      }

      @media screen and (max-width: 991px) {
        font-size: 12px;
        line-height: 16px;
        text-align: right;
      }

      @media screen and (max-width: 767px) {
        margin-top: 20px;
      }
    }
  }

  &-content {
    display: grid;
    grid-template-columns: 232px 1fr;
    grid-gap: 24px;

    @media screen and (max-width: 1365px) {
      grid-template-columns: 167px 1fr;
    }

    @media screen and (max-width: 1199px) {
      grid-template-columns: 1fr;
    }

    &__message {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 300px;
    }
  }
}
</style>