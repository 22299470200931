<template>
  <div class="profile">
    <div class="profile-section _contragent">
      <div class="profile-section__title">{{ contragent.title }}</div>
      <div class="profile-section__content">
        <div class="profile-section__content-item" v-for="(contragentItem, contragentKey) in contragent.items" :key="contragentKey">
          <div class="profile-section__content-item__title">{{ contragentItem.name }}:</div>
          <div class="profile-section__content-item__value">{{ contragentItem.value }}</div>
        </div>
      </div>
    </div>
    <div class="profile-section _limits">
      <div class="profile-section__title">{{ limits.title }}</div>
      <div class="profile-section__content">
        <div class="profile-section__content-item _title">
          <div class="profile-section__content-item__title">{{ limits.template.name }}</div>
          <div class="profile-section__content-item__value">{{ limits.template.value }}</div>
        </div>
        <div class="profile-section__content-item" v-for="(limitItem, limitKey) in limits.items" :key="limitKey">
          <div class="profile-section__content-item__title">{{ limitItem.name }}:</div>
          <div class="profile-section__content-item__value">{{ limitItem.value }}</div>
        </div>
      </div>
    </div>
    <div class="profile-section _contact" v-for="(contact, key) in contacts.items" :key="key">
      <div class="profile-section__title">{{ contacts.title }}</div>
      <div class="profile-section__items">
        <div class="profile-section__content">
          <div class="profile-section__content-item" v-for="(contactItem, contactKey) in contact" :key="contactKey">
            <div class="profile-section__content-item__title">{{ contactItem.name }}:</div>
            <div class="profile-section__content-item__value">{{ contactItem.value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import profileData from '/static/data/profile.js';
import AppHandlers from '@/mixins/AppHandlers';

export default {
  name: 'ProfilePage',
  mixins: [
    AppHandlers
  ],
  data: () => ({
    contragent: profileData.data.contragent,
    contacts: profileData.data.contacts,
    limits: profileData.data.limits
  }),
  async mounted() {
    this.$root.showPreloader();
    let response = await this.getData('/profiles');
    this.contragent = response.contragent;
    this.contacts = response.contacts;
    this.limits = response.limits;
    this.$root.hidePreloader();
  }
};
</script>

<style scoped lang="scss">
.profile {
  display: grid;
  grid-gap: 48px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  margin-bottom: 56px;

  @media screen and (max-width: 1199px) {
    grid-template-columns: 4fr 3fr;
  }

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 32px;
  }

  &-section {
    display: grid;
    grid-template-rows: 48px auto;

    @media screen and (max-width: 991px) {
      grid-column: 1/3;
    }

    &._contact {
      grid-column: 1 / 3;
      grid-template-columns: 1fr;
    }

    &._limits {
      @media screen and (max-width: 991px) {
        order: 1;
      }
    }

    &__items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 48px;

      @media screen and (max-width: 991px) {
        grid-template-columns: 1fr;
      }
    }

    &__title {
      font-family: $font-additional;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 24px;

      @media screen and (max-width: 1199px) {
        font-size: 16px;
      }

      ._contact & {
        grid-column: 1 / 3;
        margin: 0;
      }
    }

    &__content {
      display: grid;
      grid-gap: 8px;
      padding: 32px 24px;
      border: 1px solid $palette-lightgray;
      border-radius: 32px;
      max-width: 616px;

      @media screen and (max-width: 991px) {
        padding: 24px 16px;
      }

      ._limits & {
        max-width: 643px;

        @media screen and (max-width: 1365px) {
          grid-gap: 12px;
        }

        @media screen and (max-width: 991px) {
          max-width: 100%;
        }
      }

      @media screen and (max-width: 1365px) {
        max-width: 460px;
      }

      @media screen and (max-width: 991px) {
        max-width: 100%;
      }

      &-item {
        display: flex;
        font-size: 14px;
        line-height: 24px;
        font-family: $font-main;

        @media screen and (max-width: 575px) {
          flex-direction: column;
        }

        &._title {
          flex-direction: row;
          font-family: $font-additional;
        }

        ._limits & {
          flex-direction: row;
          justify-content: space-between;
        }

        &__title {
          font-weight: 500;
          margin-right: 16px;

          ._contragent & {
            @media screen and (max-width: 1365px) {
              max-width: 160px;
            }

            @media screen and (max-width: 575px) {
              max-width: unset;
            }
          }

          ._limits & {
            margin: 0;
            flex-basis: 355px;
            font-weight: 400;

            @media screen and (max-width: 575px) {
              flex-basis: 200px;
            }
          }

          ._title & {
            font-size: 16px;
            font-weight: 700;

            @media screen and (max-width: 575px) {
              font-size: 14px;
            }
          }
        }

        &__value {
          font-weight: 400;

          ._limits & {
            flex-basis: 200px;

            @media screen and (max-width: 575px) {
              flex-basis: 105px;
            }
          }

          ._title & {
            font-size: 16px;
            font-weight: 700;

            @media screen and (max-width: 575px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>