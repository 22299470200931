<template>
	<div class="fixed">
		<vue-draggable :resizable="false" :parent="true" class="fixedtimer" :class="{_minimize: minimize}" v-model:x="position.x" v-model:y="position.y" :key="circleData.radius">
			<div class="fixedtimer-content">
				<div class="fixedtimer-content__title">
					{{ text }}
				</div>
				<div class="fixedtimer-content__content">
					<TimerCount :deadline="deadline" :start="start" :color-time="colorTime" :hide-desc="hideDesc" @progress="doProgress($event)" @colorChange="changeColor($event)"/>
				</div>
			</div>
			<svg class="fixedtimer-progress" viewPort="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" ref="circle">
				<circle :r="circleData.radius" :cx="circleData.coord" :cy="circleData.coord" :stroke-width="circleData.strokeWidth" fill="transparent" stroke="url('#gradient')" :stroke-dasharray="strokeDasharray" stroke-dashoffset="0" :style="{strokeDashoffset: circleData.strokeDashoffset}"></circle>
				<defs>
					<linearGradient id="gradient" :gradientTransform="gradientAngle">
						<stop offset="30%" stop-color="#D9D9D9"/>
						<stop offset="100%" :stop-color="circleData.color"/>
					</linearGradient>
				</defs>
			</svg>
			<SvgIcon name="plus" :style="{fill: color}" class="fixedtimer-close" @click="changeVisible" v-if="minimize"/>
			<SvgIcon name="minus" :style="{fill: color}" class="fixedtimer-close" @click="changeVisible" v-else/>
		</vue-draggable>
	</div>
</template>

<script>
import TimerCount from '@/components/Elements/TimerCount';
import vueDraggable from 'vue3-draggable-resizable';
import SvgIcon from '@/components/SvgIcon';

export default {
	name: 'FixedTimer',
	components: {
		SvgIcon,
		TimerCount,
		vueDraggable
	},
	props: {
		deadline: String,
		start: String,
		colorTime: Number
	},
	data: () => ({
		position: {
			x: 100,
			y: 100
		},
		text: 'До окончания торгов осталось',
		color: '#008FD3',
		minimize: false,
		hideDesc: false,
		progress: 0,
		circleData: {
			radius: 75,
			strokeDashoffset: '472px',
			strokeWidth: '8px',
			color: '#008FD3',
			coord: 80
		},
		timer: null
	}),
	mounted() {
		let timerPosition = this.$cookies.get('timerPosition');
		if (timerPosition) {
			this.position = timerPosition;
		}
		let timerMinimize = this.$cookies.get('timerMinimize');
		if (timerMinimize === 'true') {
			this.changeVisible();
		}
		if (window.innerWidth < 991) {
			this.circleData.radius = 51;
			this.circleData.coord = 57;
			this.circleData.strokeWidth = '6px';
			this.hideDesc = true;
		}
		if (window.innerWidth < 767) {
			this.changeVisible();
		}
	},
	computed: {
		gradientAngle() {
			let angle = ((this.progress * 3.6) - 30).toFixed(2);
			return 'rotate(' + angle + ', 0.5, 0.5)';
		},
		strokeDasharray() {
			return Math.PI * (this.circleData.radius * 2);
		}
	},
	methods: {
		doProgress($event) {
			this.progress = $event;
			this.countOffset();
		},
		changeColor($event) {
			if ($event) {
				this.circleData.color = '#EE4142';
			} else {
				this.circleData.color = '#008FD3';
			}
		},
		countOffset() {
			let r = this.circleData.radius;
			let c = Math.PI * (r * 2);
			let pct = ((100 - (this.progress)) / 100) * c;
			this.circleData.strokeDashoffset = pct + 'px';
		},
		changeVisible() {
			if (this.minimize) {
				this.minimize = false;
				if (window.innerWidth < 991) {
					this.circleData.radius = 51;
					this.circleData.coord = 57;
					this.circleData.strokeWidth = '6px';
					this.hideDesc = true;
					this.position = {
						x: this.position.x - 57,
						y: this.position.y - 15
					};
				} else {
					this.circleData.radius = 75;
					this.circleData.strokeWidth = '8px';
					this.circleData.coord = 80;
					this.hideDesc = false;
					this.position = {
						x: this.position.x - 105,
						y: this.position.y - 15
					};
				}
			} else {
				this.minimize = true;
				this.circleData.radius = 18;
				this.circleData.strokeWidth = '4px';
				this.circleData.coord = 20;
				if (window.innerWidth < 991) {
					this.position = {
						x: this.position.x + 57,
						y: this.position.y + 15
					};
				} else {
					this.position = {
						x: this.position.x + 105,
						y: this.position.y + 15
					};
				}
			}
			this.countOffset();
		},
		getSaveCoord(value) {
			let result = {
				x: value.x,
				y: value.y
			};
			if (this.minimize) {
				result.x = result.x - 105;
				result.y = result.y - 15;
			}
			return result;
		}
	},
	watch: {
		minimize(value) {
			this.$cookies.set('timerMinimize', value);
			this.$cookies.set('timerPosition', this.getSaveCoord(this.position));
		},
		position: {
			handler(value) {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					this.$cookies.set('timerPosition', this.getSaveCoord(value));
				}, 500);
			},
			deep: true
		}
	}
};
</script>

<style scoped lang="scss">
.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
}

.fixedtimer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 160px;
  height: 160px;
  border: none;
  cursor: pointer;
  pointer-events: all;
  z-index: 10;

  @media screen and (max-width: 991px) {
    width: 113px;
    height: 113px;
  }

  &._minimize {
    width: 40px;
    height: 40px;
  }

  &-progress {
    position: absolute !important;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
    transition: .3s;

    & circle {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 1s linear;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: $palette-light;
    border-radius: 50%;
    padding: 20px;
    border: 1px solid $palette-lightgray;
    transition: .3s;

    @media screen and (max-width: 991px) {
      padding: 4px;
    }

    ._minimize & {
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      padding: 0;
    }

    &__title {
      font-family: $font-main;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: $palette-gray;
      text-align: center;
      margin-bottom: 8px;

      @media screen and (max-width: 991px) {
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 2px;
      }
    }

    ._minimize & {
      & * {
        display: none;
      }
    }
  }

  &-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;

    ._minimize & {
      top: -15px;
      right: -15px;
    }
  }
}
</style>

<style lang="scss">
.fixedtimer {
  & .timer-item {
    width: 36px;

    @media screen and (max-width: 991px) {
      width: 24px;
    }

    &__value {
      font-size: 28px;
      line-height: 24px;

      @media screen and (max-width: 991px) {
        font-size: 19px;
        line-height: 19px;
      }
    }
  }

  & .timer-divider {
    font-size: 28px;
    line-height: 24px;

    @media screen and (max-width: 991px) {
      font-size: 19px;
      line-height: 19px;
    }
  }
}
</style>