<template>
  <div class="preloader">
    <div class="hive" v-if="type === 'hive'">
      <div class="gel center-gel">
        <div class="hex-brick h1"></div>
        <div class="hex-brick h2"></div>
        <div class="hex-brick h3"></div>
      </div>
      <div class="gel" :class="['c'+index, (index > 19) ? 'r3' : (index > 6) ? 'r2' : 'r1']" v-for="index in items" :key="index">
        <div class="hex-brick h1"></div>
        <div class="hex-brick h2"></div>
        <div class="hex-brick h3"></div>
      </div>
    </div>
    <span class="atom" v-else></span>
  </div>
</template>

<script>
export default {
  name: 'PreLoader',
  props: {
    type: String,
    size: String
  },
  computed: {
    items() {
      if (this.size === 'small') return 6;
      if (this.size === 'medium') return 19;
      return 37;
    }
  }
};
</script>

<style lang="scss" scoped>
.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);

  & .atom {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    color: $palette-dark;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      border-radius: 50%;
      transform: rotateX(70deg);
      animation: .8s spin linear infinite;
    }

    &:after {
      color: $palette-red;
      transform: rotateY(70deg);
      animation-delay: .4s;
    }
  }

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotateZ(360deg);
    }
  }

  @keyframes rotateccw {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }

  @keyframes spin {
    0%,
    100% {
      box-shadow: .5em 0 0 0 currentcolor;
    }
    12% {
      box-shadow: .5em .5em 0 0 currentcolor;
    }
    25% {
      box-shadow: 0 .5em 0 0 currentcolor;
    }
    37% {
      box-shadow: -.5em .5em 0 0 currentcolor;
    }
    50% {
      box-shadow: -.5em 0 0 0 currentcolor;
    }
    62% {
      box-shadow: -.5em -.5em 0 0 currentcolor;
    }
    75% {
      box-shadow: 0px -.5em 0 0 currentcolor;
    }
    87% {
      box-shadow: .5em -.5em 0 0 currentcolor;
    }
  }

  & .hive {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    margin-left: -100px;
    top: 50%;
    margin-top: -100px;

    & .gel {
      height: 30px;
      width: 30px;
      transition: all .3s;
      position: absolute;
      top: 50%;
      left: 50%;

      &.center-gel {
        margin-left: -15px;
        margin-top: -15px;
        animation-name: pulse;
        animation-duration: 2s;
        animation-iteration-count: infinite;
      }

      & .hex-brick {
        background: $palette-red;
        width: 30px;
        height: 17px;
        position: absolute;
        top: 5px;
        animation-name: fade;
        animation-duration: 2s;
        animation-iteration-count: infinite;

        &.h2 {
          transform: rotate(60deg);
        }

        &.h3 {
          transform: rotate(-60deg);
        }
      }

      &.r1 {
        animation-name: pulse;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-delay: .2s;

        & > .hex-brick {
          animation-name: fade;
          animation-duration: 2s;
          animation-iteration-count: infinite;
          animation-delay: .2s;
        }
      }

      &.r2 {
        animation-name: pulse;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-delay: .4s;

        & > .hex-brick {
          animation-name: fade;
          animation-duration: 2s;
          animation-iteration-count: infinite;
          animation-delay: .4s;
        }
      }

      &.r3 {
        animation-name: pulse;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-delay: .8s;

        & > .hex-brick {
          animation-name: fade;
          animation-duration: 2s;
          animation-iteration-count: infinite;
          animation-delay: .8s;
        }
      }
    }
  }
}

.c1 {
  margin-left: -47px;
  margin-top: -15px;
}

.c2 {
  margin-left: -31px;
  margin-top: -43px;
}

.c3 {
  margin-left: 1px;
  margin-top: -43px;
}

.c4 {
  margin-left: 17px;
  margin-top: -15px;
}

.c5 {
  margin-left: -31px;
  margin-top: 13px;
}

.c6 {
  margin-left: 1px;
  margin-top: 13px;
}

.c7 {
  margin-left: -63px;
  margin-top: -43px;
}

.c8 {
  margin-left: 33px;
  margin-top: -43px;
}

.c9 {
  margin-left: -15px;
  margin-top: 41px;
}

.c10 {
  margin-left: -63px;
  margin-top: 13px;
}

.c11 {
  margin-left: 33px;
  margin-top: 13px;
}

.c12 {
  margin-left: -15px;
  margin-top: -71px;
}

.c13 {
  margin-left: -47px;
  margin-top: -71px;
}

.c14 {
  margin-left: 17px;
  margin-top: -71px;
}

.c15 {
  margin-left: -47px;
  margin-top: 41px;
}

.c16 {
  margin-left: 17px;
  margin-top: 41px;
}

.c17 {
  margin-left: -79px;
  margin-top: -15px;
}

.c18 {
  margin-left: 49px;
  margin-top: -15px;
}

.c19 {
  margin-left: -79px;
  margin-top: -15px;
}

.c20 {
  margin-left: 33px;
  margin-top: -99px;
}

.c21 {
  margin-left: 1px;
  margin-top: -99px;
}

.c22 {
  margin-left: -31px;
  margin-top: -99px;
}

.c23 {
  margin-left: -63px;
  margin-top: 69px;
}

.c24 {
  margin-left: 33px;
  margin-top: 69px;
}

.c25 {
  margin-left: 1px;
  margin-top: 69px;
}

.c26 {
  margin-left: -31px;
  margin-top: 69px;
}

.c27 {
  margin-left: -63px;
  margin-top: -99px;
}

.c28 {
  margin-left: -95px;
  margin-top: -43px;
}

.c29 {
  margin-left: -95px;
  margin-top: 13px;
}

.c30 {
  margin-left: 49px;
  margin-top: 41px;
}

.c31 {
  margin-left: -79px;
  margin-top: -71px;
}

.c32 {
  margin-left: -111px;
  margin-top: -15px;
}

.c33 {
  margin-left: 65px;
  margin-top: -43px;
}

.c34 {
  margin-left: 65px;
  margin-top: 13px;
}

.c35 {
  margin-left: -79px;
  margin-top: 41px;
}

.c36 {
  margin-left: 49px;
  margin-top: -71px;
}

.c37 {
  margin-left: 81px;
  margin-top: -15px;
}


@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fade {
  0% {
    background: $palette-red;
  }

  50% {
    background: $palette-red-dark;
  }

  100% {
    background: $palette-red;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes fade {
  0% {
    background: $palette-red;
  }

  50% {
    background: $palette-red-dark;
  }

  100% {
    background: $palette-red;
  }
}
</style>