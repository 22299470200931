<template>
	<div class="download">
		<div class="download-container" @click="download">
      <div class="download-icon">
        <SvgIcon name="excel"/>
      </div>
      <div class="download-text">{{ text }}</div>
    </div>
    <a :href="fileData" :download="fileName" ref="loadLink" v-show="ready"></a>
	</div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
import AppHandlers from '@/mixins/AppHandlers';

export default {
	name: 'DownloadButton',
	components: {SvgIcon},
	props: {
		text: String,
		url: String
	},
	emits: [],
	mixins: [
		AppHandlers
	],
	data: () => ({
		ready: false,
		fileData: '',
		fileName: ''
	}),
	methods: {
		async download() {
			this.$root.showPreloader();
			let response = await this.getData(this.url);
			this.fileName = response.file.name;
			this.fileData = response.file.data;
			setTimeout(()=>{
				this.ready = true;
				this.$refs.loadLink.click();
				this.ready = false;
				this.$root.hidePreloader();
			}, 500);
		}
	}
};
</script>

<style scoped lang="scss">
.download {
  display: flex;
  margin-top: 20px;

  &-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &-icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;

    & svg {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-text {
    font-family: $font-additional;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: $palette-red;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>