import axios from 'axios';
import {serverHost} from '@/env';

const instance = axios.create({
	baseURL: serverHost + ':8000/api/',
	headers: {
		'Content-Type': 'application/json'
	},
	timeout: 120000
});

export default instance;
