<template>
	<div class="filter">
		<div class="filter_mobile" :class="{_filter: history}" v-if="mediaQuery({MaxWidth: 1199})">
			<div class="filter-header" :class="{_active: visible}">
				<div class="filter-header__title" @click="showFilter">Фильтрация</div>
				<div class="filter-header__clear" v-if="visible" @click="clearAll">Сбросить все</div>
				<SvgIcon name="arrow" class="filter-header__arrow" @click="showFilter"/>
			</div>
			<transition name="slidedown">
				<div class="filter-content" v-if="visible">
					<div class="filter-content__sections">
						<div class="filter-content__sections-item" v-for="(prop, key) in propList" :class="[{_active: showProp === key}, key]" :key="prop.name">
							<div class="filter-sections__item-name" :class="key">
								<span>{{ prop.name }}</span>
							</div>
							<div class="filter-sections__item-values" v-if="key === 'auction'">
								<SelectList :text="currentAuction" :show="showPopup" @togglePopup="togglePopup($event)">
									<div class="filter-sections__item-values__item" v-for="(value, propKey) in prop.values" :key="value" @click="auctionSelect(propKey)">
										{{ value }}
									</div>
								</SelectList>
							</div>
							<div class="filter-sections__item-values" v-else>
								<div class="filter-sections__item-values__item" v-for="(value, propKey) in prop.values" :key="value">
									<FormInput type="checkbox" :name="propKey" :text="value" v-model="selectedProp[propKey]"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</transition>
		</div>

		<div class="filter_desktop" v-if="mediaQuery({MinWidth: 1200})">
			<div class="filter-sections">
				<div class="filter-sections__item" v-for="(prop, key) in propList" :class="{_active: showProp === key}" :key="prop.name">
					<div class="filter-sections__item-name" @click="showList(key)">
						<span>{{ prop.name }}</span>
						<SvgIcon name="arrow"/>
					</div>
					<transition name="slidedown" v-if="key === 'auction'">
						<div class="filter-sections__item-values _filter" v-if="showProp === key">
							<SelectList :text="currentAuction" :show="showPopup" @togglePopup="togglePopup($event)">
								<div class="filter-sections__item-values__item" :class="(value === currentAuction) ? 'active' : ''" v-for="(value, propKey) in prop.values" :key="value" @click="auctionSelect(propKey)">
									{{ value }}
								</div>
							</SelectList>
						</div>
					</transition>
					<transition name="slidedown" v-else>
						<perfect-scrollbar class="filter-sections__item-values" v-if="showProp === key">
							<div class="filter-sections__item-values__item" v-for="(value, propKey) in prop.values" :key="value">
								<FormInput type="checkbox" :name="propKey" :text="value" v-model="selectedProp[propKey]"/>
							</div>
						</perfect-scrollbar>
					</transition>
				</div>
			</div>
			<div class="filter-apply">
				<div class="filter-apply__title">Примененные фильтры</div>
				<perfect-scrollbar class="filter-apply__content">
					<div class="filter-apply__content-item" v-for="(item, key) in formatSelect" :key="key">
						<span>{{ item }}</span>
						<SvgIcon name="cross" class="delete" @click="deleteSelect(key)"/>
					</div>
				</perfect-scrollbar>
			</div>
			<ButtonItem text="Сбросить" :color="buttonColor" class="filter-button" @click="clearAll"/>
		</div>
	</div>
</template>

<script>
import AuctionData from '/static/data/auction.js';
import ButtonItem from '@/components/Elements/ButtonItem';
import SvgIcon from '@/components/SvgIcon';
import FormInput from '@/components/Elements/FormInput';
import MediaHandlers from '@/mixins/MediaHandlers';
import SelectList from '@/components/Elements/SelectList';

export default {
	name: 'TableFilter',
	components: {
		SelectList,
		FormInput,
		SvgIcon,
		ButtonItem
	},
	mixins: [
		MediaHandlers
	],
	props: {
		names: Object,
		items: Object,
		history: Boolean,
		auctions: Object,
		current: {
			type: String,
			default: '0'
		}
	},
	emits: [
		'apply',
		'auctionSelect'
	],
	data: () => ({
		active: false,
		propList: {},
		showProp: false,
		selectedProp: {},
		legend: AuctionData.data.legend,
		buttonColor: 'filter',
		visible: false,
		showPopup: false,
		currentAuction: '0',
		templateDate: {
			'Пн': false,
			'Вт': false,
			'Ср': false,
			'Чт': false,
			'Пт': false,
			'Сб': false,
			'Вс': false,
		}
	}),
	mounted() {
		this.initFilter();
	},
	computed: {
		formatSelect() {
			let selectProp = {};
			for (let selectKey in this.selectedProp) {
				if (this.selectedProp[selectKey]) {
					let propKey = selectKey.split('-');
					selectProp[selectKey] = this.propList[propKey[0]].values[selectKey];
				}
			}
			this.$emit('apply', selectProp);
			this.checkButton();
			return selectProp;
		}
	},
	methods: {
		initFilter() {
			if (this.history) {
				if (this.current) {
					this.currentAuction = this.current;
					this.setActiveAuction(this.currentAuction);
				}

				this.propList['auction'] = {
					name: 'Торг',
					values: this.auctions
				};
			}

			if (this.items) {
				for (let itemKey in this.items) {
					for (let propKey in this.names) {
						if (this.items[itemKey][propKey]) {
							if (propKey === 'date') {
								this.templateDate[this.formatValue(propKey, this.items[itemKey][propKey])] = true;
							} else {
								if (this.propList[propKey]) {
									if (!this.checkValueInObj(this.formatValue(propKey, this.items[itemKey][propKey]), this.propList[propKey].values)) {
										this.propList[propKey].values[propKey + '-' + itemKey] = this.formatValue(propKey, this.items[itemKey][propKey]);
									}
								} else {
									this.propList[propKey] = {name: this.names[propKey]};
									this.propList[propKey].values = {};
									this.propList[propKey].values[propKey + '-' + itemKey] = this.formatValue(propKey, this.items[itemKey][propKey]);
								}
							}
						}
					}
				}
			}

			this.propList['date'] = {
				name: 'День отгрузки',
				values: {}
			};
			let index = 1;
			for(let key in this.templateDate) {
				if (this.templateDate[key] === true) {
					this.propList['date'].values['date-'+index] = key;
					index++;
				}
			}
			this.propList['available'] = {
				name: 'Статус доступности лота',
				values: {
					'available-true': 'Доступен',
					'available-false': 'Забронирован'
				}
			};
			this.propList['status'] = {
				name: 'Тип участия за лот',
				values: {
					'status-none': 'Не ставил',
					'status-lead': 'Лидирую',
					'status-lose': 'Уступаю'
				}
			};
		},
		showList(key) {
			if (this.showProp === key) {
				this.showProp = false;
			} else {
				this.showProp = key;
			}
		},
		showFilter() {
			this.visible = !this.visible;
		},
		checkValueInObj(value, obj) {
			for (let key in obj) {
				if (obj[key] === value) {
					return true;
				}
			}
			return false;
		},
		formatValue(key, val) {
			if (key === 'date') {
				return val.day;
			}
			if (key === 'status') {
				if (this.legend[val]) {
					return this.legend[val].title;
				}
			}
			return val;
		},
		deleteSelect(key) {
			this.selectedProp[key] = false;
		},
		clearAll() {
			let select = false;

			for (let key in this.selectedProp) {
				if (this.selectedProp[key]) {
					select = true;
				}
			}
			if (select) {
				this.selectedProp = {};
			}
		},
		checkButton() {
			let active = false;
			for (let key in this.selectedProp) {
				if (this.selectedProp[key]) {
					active = true;
				}
			}
			if (active) {
				this.buttonColor = 'red';
			} else {
				this.buttonColor = 'filter';
			}
		},
		auctionSelect(key) {
			this.setActiveAuction(key);
			this.showPopup = false;
			this.$emit('auctionSelect', key);
		},
		setActiveAuction(key) {
			this.currentAuction = this.auctions[key];
		},
		togglePopup($event) {
			this.showPopup = $event;
		}
	},
	watch: {
		formatSelect() {
		}, //Костыль для мобильной версии
		items() {
			this.initFilter();
		}
	}
};
</script>

<style scoped lang="scss">
.filter {
  &_mobile {
    border-radius: 16px;
    margin-bottom: 16px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    color: $palette-dark;
    background: $palette-table;
    padding: 14px 30px 14px 9px;
    border-radius: 16px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    &._active {
      color: $palette-light;
      background: $palette-red;
      border-radius: 16px 16px 0 0;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
    }

    & * {
      transition: .3s;
    }

    &__title {
      font-family: $font-additional;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
    }

    &__clear {
      font-family: $font-additional;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    &__arrow {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%) rotate(90deg);
      width: 14px;
      height: 14px;
      fill: $palette-dark;

      ._active & {
        transform: translateY(-50%) rotate(-90deg);
        fill: $palette-light;
      }
    }
  }

  &-content {
    border-radius: 0 0 16px 16px;
    border: 1px solid $palette-lightgray;

    &__sections {
      display: grid;
      grid-template-columns: 4fr 5fr 6fr;
      grid-gap: 24px 16px;
      padding: 16px 8px 8px 8px;

			@media screen and (max-width: 1199px) {
				grid-template-columns: auto;
			}

      @media screen and (max-width: 991px) {
        grid-template-columns: 3fr 4fr;
				grid-gap: 0;
      }

      @media screen and (max-width: 575px) {
        grid-template-columns: 1fr;
      }

      &-item {

        &.auction {
          height: 34px;
        }

        &.date {
          order: 1;
        }

        &:first-child {
          grid-column: 1 / 4;

          @media screen and (max-width: 991px) {
            grid-column: 1 / 3;
          }

          @media screen and (max-width: 575px) {
            grid-column: 1 / 2;
          }

          ._filter & {
            display: flex;

            & .filter-sections__item-name {
              display: flex;
              align-items: center;
              background: $palette-lightgray;
              border-radius: 16px 0 0 16px;
              padding: 0 16px;
              font-family: $font-additional;
              font-size: 16px;
              font-weight: 700;
              line-height: 16px;
              min-width: 60px;
              height: 100%;

              @media screen and (max-width: 767px) {
                font-size: 14px;
              }
            }

            & .filter-sections__item-values {
              display: flex;
              align-items: center;
              flex-grow: 1;
              position: relative;
              border: 1px solid $palette-lightgray;
              border-radius: 0 16px 16px 0;
              padding: 0;
              height: 100%;
            }
          }
        }

        &.date {
          @media screen and (max-width: 991px) {
            grid-column: 1 / 3;
          }

          @media screen and (max-width: 575px) {
            grid-column: 1 / 2;
          }
        }

        ._filter &:nth-child(2) {
          grid-column: 1 / 4;

          @media screen and (max-width: 575px) {
            grid-column: 1 / 2;
          }
        }

      }

      & .filter-sections__item {
        &-name {
          padding: 0;
          background: none;
          margin-bottom: 12px;
        }

        &-values {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          grid-gap: 0;

          &__item {
            margin-right: 24px;
            margin-bottom: 12px;

            @media screen and (max-width: 1199px) {
              margin-right: 16px;
            }

						@media screen and (max-width: 575px) {
							margin-right: 10px;
							margin-bottom: 5px;
						}

            &:first-child {
              padding-top: 0;
            }

            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }

  &-sections {
    margin-bottom: 16px;

    &__item {
      margin-bottom: 1px;

      &:first-child {
        & .filter-sections__item-name {
          border-radius: 16px 16px 0 0;
          overflow: hidden;
          -webkit-mask-image: -webkit-radial-gradient(white, black);
        }
      }

      &:last-child {
        border-radius: 0 0 16px 16px;
        overflow: hidden;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
      }

      &-name {
        position: relative;
        padding: 16px;
        background: $palette-table;
        cursor: pointer;
        font-family: $font-additional;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;

        @media screen and (max-width: 1365px) {
          font-size: 14px;
        }

        & svg {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%) rotate(90deg);
          width: 20px;
          height: 20px;
          transition: .3s;

          @media screen and (max-width: 1365px) {
            width: 14px;
            height: 14px;
          }
        }

        ._active & svg {
          transform: translateY(-50%) rotate(-90deg);
        }
      }

      &-values {
        display: grid;
        grid-template-rows: auto;
        grid-gap: 12px;
        padding: 10px 0;

        ._desktop & {
          max-height: calc((100vh - 197px) / 3);
        }

        &._filter {
          display: block;
        }
      }
    }
  }

  &-apply {
    &__title {
      font-family: $font-additional;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      margin-bottom: 12px;
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      max-height: 92px;

      &-item {
        position: relative;
        font-family: $font-main;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        padding: 5px 16px 5px 8px;
        border-radius: 8px;
        background: $palette-grayfilter;
        margin: 8px 12px 0 0;

        & .delete {
          position: absolute;
          top: -6px;
          right: -6px;
          width: 15px;
          height: 15px;
          background: $palette-red;
          padding: 3px;
          border-radius: 50%;
          stroke: $palette-light;
          cursor: pointer;
        }
      }
    }
  }

  &-button {
    width: 100%;
    text-align: center;
    margin-top: 16px;

    @media screen and (max-width: 1365px) {
      font-size: 14px;
      line-height: 16px;
      padding: 12px;
    }
  }
}
</style>

<style lang="scss">
.filter-sections__item-values__item {
  & .checkbox {
    & label {
      justify-content: flex-start;
    }
  }
}

.list__select-popup__scroll-content .filter-sections__item-values__item {
  font-family: $font-main;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 12px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    margin-right: 0 !important;
  }

  ._filter & {
    padding: 8px 0;
    margin: 0;
  }

  &.active {
    color: #008FD3;
  }
}
</style>