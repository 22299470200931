import io from 'socket.io-client'
import TokenService from "./../services/token.service";
import {serverHost} from '@/env';

const socket = io(serverHost + ':3000');

socket.on("connectApp", (data) => {
  console.log(data)
});

function socketEmit(action, params) {
  const token = TokenService.getLocalAccessToken();
  socket.emit(action, token, params);
}

export {socket, socketEmit};
