<template>
  <nav class="header-menu">
    <div class="header-menu__item" v-for="menuItem in menu" :key="menuItem.text" :class="{ _active: isActive(menuItem) }">
      <router-link :to="menuItem.link" class="_hovered">{{ menuItem.text }}</router-link>
    </div>
  </nav>
</template>

<script>
import templateData from '/static/data/template.js';

export default {
  name: 'HeaderMenu',
  data() {
    return {
      menu: templateData.data.header.menu
    };
  },
  methods: {
    isActive(item) {
      let active = false;
      if (item.link === this.$route.path) {
        active = true;
      }
      return active;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-menu {
  display: flex;
  align-items: center;

  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: start;
    padding-bottom: 15px;
    border-bottom: 1px solid $palette-gray;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 100%;
    border-top: 2px solid transparent;
    transition: .3s;
    margin-right: 24px;

    @media screen and (max-width: 575px) {
      padding: 8px 5px;
      margin-right: 0;
    }

    &._active, &:hover {
      border-color: $palette-light;
    }

    & a {
      display: block;
      font-size: 17px;
      color: $palette-light;

      @media screen and (max-width: 1365px) {
        font-size: 15px;
      }
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
      @media screen and (max-width: 575px) {
        color: $palette-dark;
      }
    }
  }
}

._hovered {
  //padding: 8px 15px;
  border-radius: 50px;
  transition: .3s;

  @media screen and (max-width: 1365px) {
    //padding: 8px 10px;
  }

  @media screen and (max-width: 991px) {
    //padding: 8px 5px;
  }

  /*&:hover {
    background: $palette-light_10;
  }*/
}
</style>