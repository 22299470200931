<template>
	<div class="register" v-if="step !== 4">
		<div class="register-head">
			<div class="register-head__title">Регистрация</div>
			<div class="register-head__steps">
				<div class="register-head__steps-block" v-for="(stepItem, key) in steps" :key="key">
					<div class="register-head__steps-block__item" :class="[{_active: step === Number(key)}, {_passed: step > key}]">{{ stepItem }}</div>
					<SvgIcon name="arrow" v-if="key < Object.keys(steps).length"/>
				</div>
			</div>
		</div>

		<perfect-scrollbar class="register-body" v-if="step === 1">
			<div class="register-body__content">
				<FormInput type="text" name="INN" text="ИНН" :required="true" mask="inn" class="register-body__field" v-model="formResultFirst['INN']" ref="INN" @change="checkINN"/>
				<FormInput type="text" name="shortname" text="Краткое наименование организации" :required="true" class="register-body__field" v-model="formResultFirst['shortname']" ref="shortname"/>
				<FormInput type="text" name="adress" text="Адрес регистрации" :required="true" class="register-body__field" v-model="formResultFirst['adress']" ref="adress"/>
				<FormInput type="text" name="ogrn" text="ОГРН/ОГРНИП" :required="true" mask="ogrn" class="register-body__field" v-model="formResultFirst['ogrn']" ref="ogrn"/>
				<FormInput type="text" name="fullname" text="Полное наименование организации/ИП" :required="true" class="register-body__field" v-model="formResultFirst['fullname']" ref="fullname"/>
				<FormInput type="text" name="postadress" text="Почтовый адрес" :required="true" class="register-body__field" v-model="formResultFirst['postadress']" ref="postadress"/>
				<div class="register-body__text">
					Скан-копии документов должны быть отсканированы в формате PDF, один файл должен равняться одному документу, каждый документ должен иметь наименование, соответствующее его содержанию
				</div>
				<FormInput type="file" name="file1" text="Свидетельство о государственной регистрации юридического лица или свидетельство о государственной регистрации физического лица в качестве индивидуального предпринимателя" :required="true" v-model="formResultFirst['file1']" ref="file1"/>
				<FormInput type="file" name="file2" text="Устав полностью" :required="true" v-model="formResultFirst['file2']" ref="file2"/>
				<FormInput type="file" name="file3" text="Свидетельство о постановке на учет в налоговом органе" :required="true" v-model="formResultFirst['file3']" ref="file3"/>
				<FormInput type="file" name="file4" text="Документ об избрании/назначении руководителя/лица, выполняющего функции единоличного исполнительного органа юридического лица и подтверждающий его полномочия действовать от имени юридического лица без доверенности" :required="true" v-model="formResultFirst['file4']" ref="file4"/>
				<FormInput type="file" name="file5" text="Доверенность, выданная уполномоченным органом юридического лица, на заключение настоящего Договора" :required="true" v-model="formResultFirst['file5']" ref="file5"/>
				<FormInput type="file" name="file6" text="Уведомление о возможности применения упрощенной системы налогообложения" :required="true" v-model="formResultFirst['file6']" ref="file6"/>
				<FormInput type="file" name="file7" text="Заполненная Покупателем карточка клиента, заверенная печатью, с указанием главного бухгалтера и иных лиц, имеющих право подписания договоров, спецификаций, накладных, счетов, счетов-фактур, УПД и иных документов с обязательной информацией об уровне компартмента" :required="true" :example="{text: 'Скачать шаблон карточки клиента', link: '/files/Test.pdf'}" v-model="formResultFirst['file7']" ref="file7"/>
			</div>
			<div class="register-body__control">
				<ButtonItem text="Далее" color="red" @click="firstStep"/>
			</div>
		</perfect-scrollbar>

		<perfect-scrollbar class="register-body" v-if="step === 2">
			<div class="register-body__content">
				<FormInput type="text" name="name" text="Имя контактного лица" :required="true" class="register-body__field" v-model="formResultSecond['name']" ref="name"/>
				<FormInput type="text" name="surname" text="Фамилия контактного лица" :required="true" class="register-body__field" v-model="formResultSecond['surname']" ref="surname"/>
				<FormInput type="text" name="email" text="E-mail контактного лица" :required="true" mask="email" class="register-body__field" v-model="formResultSecond['email']" ref="email"/>
				<FormInput type="text" name="mobile" text="Мобильный телефон контактного лица" :required="true" mask="phone" class="register-body__field" v-model="formResultSecond['mobile']" ref="mobile"/>
				<FormInput type="text" name="phone" text="Стационарный телефон контактного лица" :required="true" class="register-body__field" v-model="formResultSecond['phone']" ref="phone"/>
				<FormInput type="text" name="position" text="Должность контактного лица" :required="true" class="register-body__field" v-model="formResultSecond['position']" ref="position"/>
				<FormInput type="checkbox" name="policy" text="Я даю согласие на обработку моих персональных данных и соглашаюсь с условиями <a href='/policy'>политики конфиденциальности</a> в отношении обработки и защиты персональных данных" :required="true" class="register-body__field" v-model="formResultSecond['policy']" ref="policy"/>
			</div>
			<div class="register-body__control">
				<ButtonItem text="Назад" color="gray" @click="stepBackward"/>
				<ButtonItem text="Далее" color="red" @click="secondStep"/>
			</div>
		</perfect-scrollbar>

		<perfect-scrollbar class="register-body" v-if="step === 3">
			<div class="register-body__content">
				<div class="register-body__title">
					Интересующие типы продукции
				</div>
				<FormInput
					v-for="(item) in productTypes"
					type="checkbox"
					:name="item.id"
					:text="item.name"
					:key="item.id"
					class="register-body__field"
					:ref="item.id"
					v-model="formResultThird[item.id]"
				/>
				<div class="error-text">
					{{ errorText }}
				</div>
			</div>
			<div class="register-body__control">
				<ButtonItem text="Назад" color="gray" @click="stepBackward"/>
				<ButtonItem text="Зарегистрироваться" color="red" @click="register"/>
			</div>
		</perfect-scrollbar>
	</div>

	<div class="register" v-if="step === 4">
		<perfect-scrollbar class="register-body" v-if="step === 4">
			<div class="register-body__success">
				<div class="register-body__success-title">Заявка на регистрацию отправлена.</div>
				<div class="register-body__success-text">
					Окончательное решение о предоставлении доступа к аукционной площадке принимает ООО “Коралл”, после получения и рассмотрения документов, приложенных в процессе регистрации. ООО “Коралл” вправе запросить дополнительные документы, подтверждающие достоверность предоставленных сведений
				</div>
			</div>
		</perfect-scrollbar>
	</div>
</template>

<script>
import FormInput from '@/components/Elements/FormInput';
import ButtonItem from '@/components/Elements/ButtonItem';
import SvgIcon from '@/components/SvgIcon';
import axios from 'axios';
import AppHandlers from '@/mixins/AppHandlers';

export default {
	name: 'RegisterForm',
	components: {
		SvgIcon,
		ButtonItem,
		FormInput
	},
	mixins: [
		AppHandlers
	],
	emits: {
		changeStep: null
	},
	data: () => ({
		step: 1,
		steps: {
			1: 'Шаг 1',
			2: 'Шаг 2',
			3: 'Шаг 3'
		},
		formResultFirst: {
			'INN': '',
			'shortname': '',
			'adress': '',
			'ogrn': '',
			'fullname': '',
			'postadress': '',
			'file1': '',
			'file2': '',
			'file3': '',
			'file4': '',
			'file5': '',
			'file6': '',
			'file7': ''
		},
		formResultSecond: {
			'name': '',
			'surname': '',
			'email': '',
			'mobile': '',
			'phone': '',
			'position': '',
			'policy': ''
		},
		formResultThird: {},
		errorText: '',
		productTypes: []
	}),
	mounted() {
		this.event();
		this.getProductTypes();
	},
	methods: {
		async checkINN() {
			if (this.formResultFirst['INN']) {
				let innData = {};

				let token = '91e8a530f320a0eef7cbe9b672681ff38b357e7b';
				let daData = await axios.create({
					baseURL: 'https://suggestions.dadata.ru/',
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Authorization': 'Token ' + token
					}
				});

				await daData.post('suggestions/api/4_1/rs/findById/party', {
					query: this.formResultFirst['INN']
				}).then((response) => {
					if (response.data.suggestions[0]) {
						let company = response.data.suggestions[0].data;
						innData['shortname'] = company.name.short_with_opf;
						innData['fullname'] = company.name.full_with_opf;
						innData['adress'] = company.address.value;
						innData['ogrn'] = company.ogrn;
					}
				});

				for (let key in innData) {
					console.log(this.formResultFirst[key] + ' - ' + innData[key]);
					this.formResultFirst[key] = innData[key];
				}
			}
		},
		firstStep() {
			let failed = false;
			for (let key in this.formResultFirst) {
				this.$refs[key].checkField();
				if (this.formResultFirst[key] === '')
					failed = true;
			}
			if (!failed) {
				this.step++;
			}

			this.event();
		},
		secondStep() {
			let failed = false;
			for (let key in this.formResultSecond) {
				this.$refs[key].checkField();
				if ((this.formResultSecond[key] === '') || (this.formResultSecond[key] === false)) {
					failed = true;
				}
			}
			if (!failed) {
				this.step++;
			}

			console.log(this.productTypes);
			this.event();
		},
		register() {
			let failed = true;
			if (Object.keys(this.formResultThird).length) {
				for (let key in this.formResultThird) {
					if (this.formResultThird[key] === true) {
						failed = false;
					}
				}
			} else {
				failed = true;
			}

			if (!failed) {
				this.registerSend();
			} else {
				this.errorText = 'Необходимо выбрать минимум один тип продукции';
			}
		},
		stepBackward() {
			this.step--;
		},
		event() {
			this.$emit('changeStep', this.step);
		},

		async registerSend() {
			this.$root.showPreloader();

				let formData = new FormData();

				formData.append('INN', this.formResultFirst['INN']);
				formData.append('shortname', this.formResultFirst['shortname']);
				formData.append('adress', this.formResultFirst['adress']);
				formData.append('ogrn', this.formResultFirst['ogrn']);
				formData.append('fullname', this.formResultFirst['fullname']);
				formData.append('postadress', this.formResultFirst['postadress']);

				formData.append('file1', this.formResultFirst['file1']);
				formData.append('file2', this.formResultFirst['file2']);
				formData.append('file3', this.formResultFirst['file3']);
				formData.append('file4', this.formResultFirst['file4']);
				formData.append('file5', this.formResultFirst['file5']);
				formData.append('file6', this.formResultFirst['file6']);
				formData.append('file7', this.formResultFirst['file7']);

				formData.append('name', this.formResultSecond['name']);
				formData.append('surname', this.formResultSecond['surname']);
				formData.append('email', this.formResultSecond['email']);
				formData.append('mobile', this.formResultSecond['mobile']);
				formData.append('phone', this.formResultSecond['phone']);
				formData.append('position', this.formResultSecond['position']);
				formData.append('policy', this.formResultSecond['policy']);

				let productTypeSelected = [];
				if (Object.keys(this.formResultThird).length) {
					for (let key in this.formResultThird) {
						if (this.formResultThird[key] === true) {
							productTypeSelected.push(key);
						}
					}
				}

				formData.append('productTypes', productTypeSelected);

				let response = await this.postDataFormData('/registers', formData);

				if (response.title === 'An error occurred') {
					this.errorText = response.detail;
				} else {
					this.step++;
					this.event();
				}

			this.$root.hidePreloader();
		},

		async getProductTypes() {
			this.productTypes = await this.getDataWithoutToken('/product_types_lists');
			this.productTypes.forEach((element) => {
				this.formResultThird[element.id] = false;
			});
		}
	},
	watch: {
		step(val) {
			console.log(val);
		}
	}
};
</script>

<style scoped lang="scss">
.register {
  display: flex;
  flex-direction: column;
  max-height: inherit;
  height: 100%;

  &-head {
    display: flex;
    justify-content: space-between;
    padding: 0 100px;
    margin-bottom: 24px;

    @media screen and (max-width: 1365px) {
      padding: 0 70px;
    }

    @media screen and (max-width: 1199px) {
      padding: 0 50px;
    }

    @media screen and (max-width: 991px) {
      padding: 0 45px;
    }

    @media screen and (max-width: 767px) {
      padding: 0 16px;
    }

    @media screen and (max-width: 575px) {
      flex-direction: column;
      margin-bottom: 16px;
    }

    &__title {
      font-family: $font-additional;
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;

      @media screen and (max-width: 1199px) {
        font-size: 24px;
        line-height: 32px;
      }

      @media screen and (max-width: 575px) {
        margin-bottom: 10px;
      }
    }

    &__steps {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 235px;

      @media screen and (max-width: 1199px) {
        max-width: 204px;
      }

      &-block {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__item {
          font-family: $font-additional;
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          color: $palette-dark-40;

          @media screen and (max-width: 1199px) {
            font-size: 16px;
            line-height: 20px;
          }

          &._active {
            color: $palette-red;
          }

          &._passed {
            color: $palette-dark;
          }
        }

        & svg {
          width: 8px;
          height: 15px;
          opacity: .6;
          margin-left: 16px;
        }
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: 0 100px;

    @media screen and (max-width: 1365px) {
      padding: 0 70px;
    }

    @media screen and (max-width: 1199px) {
      padding: 0 50px;
    }

    @media screen and (max-width: 991px) {
      padding: 0 45px;
    }

    @media screen and (max-width: 767px) {
      padding: 0 16px;
    }

    ._success & {
      padding: 0;
    }

    &__content {
      flex-grow: 1;
    }

    &__success {
      &-title {
        font-family: $font-additional;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        color: $palette-red;
        margin-bottom: 24px;
      }

      &-text {
        font-family: $font-additional;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 32px;
      }
    }

    &__field {
      margin-bottom: 16px;
    }

    &__title {
      font-family: $font-additional;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 16px;
    }

    &__text {
      font-family: $font-additional;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: $palette-dark;
      opacity: .6;
      margin-top: 24px;
      margin-bottom: 8px;
    }

    &__control {
      & .button {
        margin-top: 40px;
        margin-right: 24px;
      }
    }
  }
}
</style>

<style lang="scss">
.register-body__field.checkbox {
  justify-content: flex-start;

  & label {
    justify-content: flex-start;
    align-items: flex-start;
    font-family: $font-additional;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #74777A;

    & a {
      color: $palette-dark;
      text-decoration: underline;
    }
  }
}
</style>