<template>
	<div class="profile">
		<div class="profile-head _process" :class="{_user: !managerCheck()}">
			<div class="process__select">
				<div class="process__select-title">Торг</div>
				<div class="process__select-body">
					<div class="process__select-body__item" @click="showSelect">
						<span v-if="errorText === ''">({{ currentItem.id }}) {{ currentItem.name }}</span>
						<span v-else>{{errorText}}</span>
						<SvgIcon name="arrow"/>
					</div>
					<transition name="fade">
						<div class="process__select-popup" v-if="visible">
							<perfect-scrollbar class="process__select-popup__scroll">
								<div class="process__select-popup__scroll-content">
									<div class="process__select-popup__item" v-for="(item, key) in items" :key="key" :class="checkActive(key)" @click="switchCurrentItem(key)">({{ item.id }}) {{ item.name }}</div>
								</div>
							</perfect-scrollbar>
						</div>
					</transition>
					<transition name="fade">
						<div class="process__select-overlay" v-if="visible" @click="hideSelect"></div>
					</transition>
				</div>
			</div>
			<div class="profile-head__navigation" v-if="mediaQuery({MinWidth: 768})">
				<ButtonItem text="Процесс торгов" color="gray" class="profile-head__navigation-item _inner _active"/>
				<ButtonItem text="Активность пользователей" link="/profile/reports/activity" color="gray" class="profile-head__navigation-item _inner" v-if="managerCheck()"/>
				<ButtonItem text="Выбранные типы продукции" link="/profile/reports/sections" color="gray" class="profile-head__navigation-item _inner" v-if="managerCheck()"/>
			</div>
			<SelectList text="Процесс торгов" class="profile-head__select" :show="showPopup" @togglePopup="this.showPopup = $event" v-if="mediaQuery({MaxWidth: 767})">
				<router-link class="_active" to="#" @click="this.showPopup = false">Процесс торгов</router-link>
				<router-link to="/profile/reports/activity" v-if="managerCheck()">Активность пользователей</router-link>
				<router-link to="/profile/reports/sections" v-if="managerCheck()">Выбранные типы продукции</router-link>
			</SelectList>
		</div>
		<div class="process-body" v-show="componentKey" v-if="errorText === ''">
			<div class="process-body__head">
				<div class="process-body__head-item" v-for="item in currentItem.data" :key="item.name">
					<b>{{ item.name }}</b><span>{{ item.value }}</span>
				</div>
			</div>
			<div class="process-body__table">
				<TableSimple :columns="columns" :items="currentItem.items" :key="componentKey"/>
			</div>
		</div>
		<DownloadButton v-if="managerCheck()" text="Выгрузить отчет" :url="'/get_auctions/'+currentKey+'?export=true'" class="profile-button"/>
	</div>
</template>

<script>
import ReportData from '/static/data/reports.js';
import ButtonItem from '@/components/Elements/ButtonItem';
import TableSimple from '@/components/Elements/TableSimple';
import SvgIcon from '@/components/SvgIcon';
import MediaHandlers from '@/mixins/MediaHandlers';
import SelectList from '@/components/Elements/SelectList';
import DownloadButton from '@/components/Elements/DownloadButton';
import AppHandlers from '@/mixins/AppHandlers';
import {socket} from '@/helpers/Socket';

export default {
	name: 'ProcessReport',
	components: {
		DownloadButton,
		SvgIcon,
		TableSimple,
		ButtonItem,
		SelectList
	},
	mixins: [
		MediaHandlers,
		AppHandlers
	],
	data: () => ({
		columns: ReportData.data.process.template,
		items: ReportData.data.process.items,
		visible: false,
		currentKey: 0,
		showPopup: false,
		componentKey: 0,
		active: false,
		errorText: ''
	}),
	computed: {
		currentItem() {
			return this.items[this.currentKey];
		}
	},
	async mounted() {
		this.$root.showPreloader();
		let response = await this.getData('/get_auctions?type=process');
		if (response.title === 'An error occurred') {
			this.errorText = response.detail;
			this.items = {};
		} else {
			this.updateData(response);
		}
		socket.on('update-report', (data) => {
			this.updateData(data);
		});

		this.$root.hidePreloader();
	},
	methods: {
		updateData(data) {
			this.columns = data.template;
			this.items = data.items;
			this.currentKey = Object.keys(this.items)[0];
			this.componentKey++;
			console.log('updated');
		},
		showSelect() {
			this.visible = true;
		},
		hideSelect() {
			this.visible = false;
		},
		checkActive(key) {
			if (this.currentKey === key) {
				return '_active';
			}
		},
		switchCurrentItem(key) {
			this.currentKey = key;
			this.visible = false;
			this.componentKey++;
		}
	},
	watch: {
		currentItem(val) {
			if (val) {
				this.active = val['active'];
			}
		}
	}
};
</script>

<style scoped lang="scss">
.process {
  &__select {
    display: flex;
    width: 100%;
    max-width: 625px;
    height: 44px;

    @media screen and (max-width: 1365px) {
      max-width: 352px;
    }

    @media screen and (max-width: 1199px) {
      max-width: unset;
      order: 1;
      margin-top: 24px;
    }

    @media screen and (max-width: 991px) {
      max-width: unset;
      order: 1;
      margin-top: 24px;
    }

    @media screen and (max-width: 767px) {
      max-width: 50%;
      order: 0;
    }

    @media screen and (max-width: 575px) {
      max-width: unset;
      order: 1;
      margin-top: 8px;
    }

    &-title {
      display: flex;
      align-items: center;
      background: $palette-lightgray;
      border-radius: 16px 0 0 16px;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      padding: 0 16px;
      font-family: $font-additional;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      min-width: 60px;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    &-body {
      display: flex;
      align-items: center;
      flex-grow: 1;
      position: relative;
      border: 1px solid $palette-lightgray;
      border-radius: 0 16px 16px 0;
      padding: 0 32px 0 16px;

      &__item {
        font-family: $font-main;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        cursor: pointer;

        & span {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media screen and (max-width: 1365px) {
            max-width: 230px;
          }

          @media screen and (max-width: 1199px) {
            max-width: unset;
          }

          @media screen and (max-width: 767px) {
            max-width: 230px;
          }
        }
      }

      & svg {
        position: absolute;
        top: 14px;
        right: 0;
        width: 8px;
        height: 13px;
        fill: $palette-dark;
        transform: rotate(90deg);
        margin: 0 10px;

        @media screen and (max-width: 767px) {

        }
      }
    }

    &-popup {
      position: absolute;
      top: 0;
      left: 0;
      width: 500px;
      height: 100vh;
      max-height: 460px;
      background: $palette-light;
      padding: 7px 16px 12px 5px;
      border-radius: 0 16px 16px 16px;
      outline: 1px solid $palette-table;
      z-index: 7;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black);

      @media screen and (max-width: 1199px) {
        width: 100%;
      }

      @media screen and (max-width: 767px) {
        width: 480px;
      }

      @media screen and (max-width: 575px) {
        width: auto;
      }

      &__scroll {
        height: 100%;
      }

      &__item {
        font-family: $font-main;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        padding: 7px 8px 7px 11px;
        cursor: pointer;

        &:hover {
          background: #EEEEEE;
        }

        &._active {
          color: #008FD3;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-overlay {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(1px);
      z-index: 6;
    }
  }

  &-body {
    &__head {
      column-count: 2;
      padding: 15px 24px;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: $palette-lightgray;
      border-radius: 16px 16px 0 0;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black);

      @media screen and (max-width: 767px) {
        column-count: 1;
      }

      @media screen and (max-width: 575px) {
        padding: 20px 12px;
      }

      &-item {
        display: flex;
        margin: 8px 0;

        & b {
          font-family: $font-additional;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          margin-right: 16px;

          @media screen and (max-width: 1199px) {
            font-size: 14px;
            line-height: 16px;
          }

          @media screen and (max-width: 991px) {
            margin-right: 8px;
          }
        }

        & span {
          font-family: $font-main;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;

          @media screen and (max-width: 1199px) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }

    &__table {
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-color: $palette-lightgray;
      border-radius: 0 0 16px 16px;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black);

      @media screen and (max-width: 767px) {
        overflow-x: scroll;
      }
    }
  }
}
</style>

<style lang="scss">
.process-head__select-popup__scroll.ps--active-y .ps__rail-y {
  background: #EEEEEE;
}

._process .list__select-body {
  max-width: 175px;
}

.process-body__table table {
  min-width: 715px;
}
</style>