let data = {
	header: {
		menu: [
			{
				link: '/rules',
				text: 'Правила торгов'
			},
			{
				link: '/delivery',
				text: 'Условия доставки и оплаты'
			},
			{
				link: '/contacts',
				text: 'Контакты'
			}
		]
	},
	contacts: {
		data: {
			name: 'ООО "Коралл"',
			phone: '8-800-250-81-54',
			email: 'info@coral-meat.ru'
		},
		adress: {
			production: {
				title: 'Производство',
				content: '171940, Россия, Тверская область, Бежецкий район, деревня Викторово'
			},
			moscowOffice: {
				title: 'Офис в Бежецке',
				content: '171940, Россия, Тверская область, г. Бежецк, ул. Большая Штабская 1д'
			},
			bezheckOffice: {
				title: 'Офис в Москве',
				content: '121170, Россия, г. Москва, м. Кутузовская,<br> ул. Поклонная д.3, к4, вход D, 11 этаж'
			}
		},
		map: [
			{
				text: '171940, Россия, Тверская область, Бежецкий район, деревня Викторово',
				coords: [57.773524, 36.350274]
			},
			{
				text: '171940, Россия, Тверская область, г.Бежецк, ул. Большая Штабская 1д',
				coords: [57.781217, 36.668008]
			},
			{
				text: '121170, Россия, г. Москва, м. Кутузовская,<br> ул. Поклонная д.3, к4, вход D, 11 этаж',
				coords: [55.736204, 37.531657]
			}
		]
	},
	profile: {
		menu: [
			{
				link: '/profile',
				text: 'Профиль'
			},
			{
				link: '/profile/auction',
				text: 'Аукционная площадка'
			},
			{
				link: '/profile/history',
				text: 'История торгов'
			},
			{
				link: '/profile/reports',
				text: 'Отчеты'
			},
			{
				link: '/profile/settings',
				text: 'Настройки'
			}
		]
	},
	index: {
		title: 'Аукционная площадка',
		subtitle: 'На сайте вы можете принять участие в онлайн-торгах продукции высокого качества, выпускаемой компанией «Коралл».',
		text: '<p>С правилами регистрации, условиями проведения торгов и обязанностями сторон вы можете ознакомиться в одноименных разделах сайта.</p><p>Подробная информация о компании на сайте <a href="#" target="_blank" style="white-space: nowrap;">coral-meat.ru</a></p>'
	},
	rules: {
		content: [
			'Сведения о лотах, представленных на аукционе, их описание, цена и любая иная информация размещаются исключительно в информационных целях и служат для ускорения согласования и заключения обычных сделок.',
			'Любые сведения и действия на аукционной площадке не несут юридических последствий. При этом подразумевается, что все предложения, сделанные на аукционной площадке, носят реальный характер и будут исполнены. В случае систематического несоблюдения данного принципа, организатор вправе лишить участника доступа к аукционной площадке.',
			'Если выигранные лоты не были подтверждены оплатой покупателя за два рабочих дня до даты отгрузки, организатор вправе приступить к дальнейшей реализации лотов.'
		]
	},
	delivery: {
		content: {
			payment: {
				title: 'Оплата',
				text: '<p>Конечная стоимость лота состоит из стоимости продукции на момент окончания торгов. В случае покупки услуг по доставке, стоимость доставки оплачивается дополнительно. Информацию по возможности, стоимости и сроках доставки можно получить по контактным данным, указанным на аукционной площадке.</p><h3>Способ оплаты - предоплата</h3><p>Сделка оплачивается заказчиком не позднее чем за 2 рабочих дня до фактической отгрузки.</p>'
			},
			delivery: {
				title: 'Доставка',
				text: '<p>Наша компания предоставляет услуги по доставке продукции мясоперерабатывающего комплекса на специализированных автомобилях, оборудованных всеми необходимыми техническими средствами для обеспечения максимальной сохранности продукции.</p>',
				type: {
					title: 'Типы доставки рефрижераторами',
					items: [
						{
							title: '5 тонн',
							image: '/images/content/1.svg'
						},
						{
							title: '10 тонн',
							image: '/images/content/2.svg'
						},
						{
							title: '20 тонн',
							image: '/images/content/3.svg'
						}
					]
				}
			}
		}
	}
};

export default {
	data
};