<template>
  <div class="settings">
    <div class="settings-container">
      <div class="settings-section" v-for="(fieldItem, fieldKey) in settings" :key="fieldKey">
        <div class="settings-section__title">{{ fieldItem.title }}</div>
        <div class="settings-section__content">
          <div class="settings-section__content-item _checkbox" v-if="fieldItem.field.type === 'tablecheckbox'">
            <table>
              <thead>
              <tr>
                <td></td>
                <td class="title" v-for="(checkboxPosition, checkboxKey) in fieldItem.field.positions" :key="checkboxKey">{{ checkboxPosition.title }}</td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(checkboxItem, checkboxItemKey) in fieldItem.field.items" :key="checkboxItemKey">
                <td class="title">{{ checkboxItem.title }}</td>
                <td v-for="(checkboxPosition, checkboxKey) in fieldItem.field.positions" :key="checkboxKey">
                  <FormInput type="checkbox" v-model="values[buildName(checkboxItem.name, checkboxPosition.name)]" :name="buildName(checkboxItem.name, checkboxPosition.name)"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <FormInput :type="fieldItem.field.type" v-model="password" :name="fieldItem.field.name" :text="fieldItem.field.text" class="settings-section__content-item" v-else/>
        </div>
      </div>
    </div>
    <ButtonItem text="Сохранить" color="red" class="settings-button" @click="saveSettings"/>
  </div>
</template>

<script>
import settingsData from '/static/data/settings.js';
import ButtonItem from '@/components/Elements/ButtonItem';
import FormInput from '@/components/Elements/FormInput';
import AppHandlers from '@/mixins/AppHandlers';

export default {
  name: 'SettingsPage',
  components: {
    FormInput,
    ButtonItem
  },
  mixins: [
    AppHandlers
  ],
  data: () => ({
    settings: settingsData.data.elements,
    password: "",
    values: {}
  }),
  async mounted() {
    this.$root.showPreloader();
    let values = {};

    let response = await this.getData("/settings");
    this.settings = response.elements;

    response.values.forEach(function(item){
      values[item] = true;
    });
    this.values = values;
    this.$root.hidePreloader();
  },
  methods: {
    async saveSettings() {
      this.$root.showPreloader();
      await this.postData("/settings", {password: this.password, events: this.values});
      this.$root.hidePreloader();
    },
    setChecked(item) {
      return this.values.find((i) => i === item) !== -1;
    },
    buildName(type, event) {
      return type + '||' + event;
    }
  }
};
</script>

<style scoped lang="scss">
.settings {

  &-container {
    display: grid;
    grid-gap: 80px;
    margin-bottom: 40px;

    @media screen and (max-width: 1365px) {
      grid-gap: 40px;
    }

    @media screen and (max-width: 1199px) {
      grid-gap: 32px;
    }
  }

  &-section {

    &__title {
      font-family: $font-additional;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 24px;

      @media screen and (max-width: 1199px) {
        font-size: 16px;
      }
    }

    &__content {
      &-item {
        max-width: 488px;

        &._checkbox {
          width: 100%;
          max-width: 798px;
          border: 1px solid $palette-table;
          border-radius: 32px;
          overflow: hidden;
          -webkit-mask-image: -webkit-radial-gradient(white, black);

          @media screen and (max-width: 1199px) {
            max-width: 688px;
          }
        }

        & table {
          width: 100%;
          border: 1px solid $palette-table;
          border-radius: 32px;
          overflow: hidden;
          -webkit-mask-image: -webkit-radial-gradient(white, black);
          border-collapse: collapse;

          & thead .title {
            text-align: center;
          }

          & td {
            padding: 16px 24px;
            border: 1px solid $palette-table;
            vertical-align: center;

            @media screen and (max-width: 1199px) {
              padding: 10px 20px;
            }

            @media screen and (max-width: 767px) {
              padding: 10px 15px;
            }

            @media screen and (max-width: 575px) {
              padding: 10px 10px;
            }

            &:first-child {
              min-width: 203px;

              @media screen and (max-width: 575px) {
                min-width: unset;
              }
            }

            &.title {
              font-family: $font-additional;
              font-size: 16px;
              font-weight: 700;
              line-height: 20px;

              @media screen and (max-width: 1199px) {
                font-size: 14px;
              }

              @media screen and (max-width: 575px) {
                font-size: 12px;
                line-height: 12px;
              }
            }
          }

          & input {
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }

  &-button {
    margin-bottom: 50px;
  }
}
</style>