export default {
	data() {
		return {
			cursorPosition: {
				x: 0,
				y: 0
			}
		};
	},
	methods: {
		checkCursorPosition(e) {
			let posX = 0,
				posY = 0;
			if (window.innerWidth >= 992) {
				posX = (e.clientX / window.innerWidth) * -1;
				posY = (e.clientY / window.innerHeight) * -1;
			}
			this.cursorPosition = {
				x: posX,
				y: posY
			};
		},
		paralaxMove(type, multiplier) {
			let posX = this.cursorPosition.x * multiplier,
				posY = this.cursorPosition.y * multiplier;
			if (type === 'bg') {
				return posX + 'px ' + posY + 'px';
			}
			if (type === 'block') {
				return 'translate(' + posX + 'px, ' + posY + 'px)';
			}
			return false;
		}
	}
};