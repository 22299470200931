<template>
  <table>
    <thead>
    <tr>
      <th v-for="(column, key) in tableColumns" @click="sortBy(key)" :class="[{ active: sortKey === key }, key]" :key="key">
        {{ column }} <span class="arrows" v-if="checkSort(key)" :class="cssSort(key)"><SvgIcon name="arrow" class="up"/><SvgIcon name="arrow" class="down"/></span>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in filtredItems" :key="item[0]">
      <td v-for="(column, key) in tableColumns" :key="key" v-html="item[key]"></td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
import AppHandlers from '@/mixins/AppHandlers';

export default {
  name: 'TableSimple',
  components: {SvgIcon},
  props: {
    items: Array,
    columns: Object,
    excludeSort: Array,
    sort: {
      type: Boolean,
      default: true
    }
  },
  mixins: [
		AppHandlers
  ],
  data: () => ({
    sortKey: '',
    sortOrder: {},
    tableColumns: {},
    filtredItems: {}
  }),
  mounted() {
    this.tableColumns = this.columns;

		if (this.tableColumns.shortName) {
			if (!this.managerCheck()) {
				delete this.tableColumns.shortName;
      }
    }

    if (window.innerWidth < 1365) {
      if (this.tableColumns.id) {
        this.tableColumns.id = 'ID';
      }
    }

    this.filterItems();
  },
  methods: {
    cssSort(key) {
      if (this.sortOrder[key]) {
        return this.sortOrder[key] > 0 ? 'asc' : 'desc'
      } else {
        return false;
      }
    },
    sortBy(key) {
      if (this.sort) {
        if (this.excludeSort) {
          if (this.excludeSort.includes(key))
            return false;
        }
        this.sortKey = key;
        this.sortOrder[key] = this.sortOrder[key] ? this.sortOrder[key] * -1 : 1;
        this.filterItems();
      }
    },
    filterItems() {
      let sortKey = this.sortKey;
      let order = this.sortOrder[this.sortKey] || 1;
      let items = this.items;

      if (sortKey) {
        items = items.slice().sort(function (a, b) {
          a = a[sortKey];
          b = b[sortKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      this.filtredItems = items;
    },
    checkSort(key) {
      if (this.excludeSort) {
        return !this.excludeSort.includes(key);
      } else {
        return true;
      }
    }
  }

};
</script>

<style scoped lang="scss">
table {
  width: 100%;
  border-collapse: collapse;
  z-index: 1;

  & tr {
    border-bottom: 1px solid $palette-lightgray;

    &:last-child {
      border-bottom: none;
    }

    &:nth-child(2n) {
      background: $palette-table;
    }

    & th {
      position: relative;
      background-color: $palette-red;
      cursor: pointer;
      user-select: none;
      font-family: $font-additional;
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      color: $palette-light;
      text-align: left;

      @media screen and (max-width: 1365px) {
        font-size: 16px;
        line-height: 20px;
      }

      @media screen and (max-width: 1199px) {
        font-size: 14px;
        line-height: 16px;
      }

      @media screen and (max-width: 767px) {
        font-size: 12px;
        line-height: 16px;
      }

      &:first-child {
        border-left-color: $palette-red;
      }
      &:last-child {
        border-right-color: $palette-red;
      }

      &.active {
        background: $palette-red-dark;

        & .arrow {
          opacity: 1;
        }
      }

      & .arrows {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 1px;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);

        @media screen and (max-width: 1365px) {
          right: 5px;
        }

        & svg {
          width: 8px;
          height: 8px;
          stroke: $palette-light;
          opacity: .5;

          &.up {
            transform: rotate(-90deg);
          }

          &.down {
            transform: rotate(90deg);
          }
        }
      }
    }

    & td {
      padding: 10px 20px;
      font-family: $font-main;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      @media screen and (max-width: 767px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    & th, & td {
      border-right: 1px solid $palette-lightgray;
      padding: 12px 24px;

      @media screen and (max-width: 1365px) {
        padding: 12px;
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.arrows.asc svg.up {
  opacity: 1;
}
.arrows.desc svg.down {
  opacity: 1;
}
</style>