<template>
  <router-link class="button" :class="color" :to="link" v-if="(link && !reload)">{{ text }}</router-link>
  <a class="button" :class="color" :href="link" v-else-if="(link && reload)">{{ text }}</a>
  <span class="button" :class="color" @click="$emit('click', $event)" v-else>{{ text }}</span>
</template>

<script>
export default {
  name: 'ButtonItem',
  props: {
    text: String,
    link: String,
    color: String,
    reload: Boolean
  },
  emits: [
    'click'
  ]
};
</script>

<style lang="scss">
.button {
  display: inline-block;
  text-transform: uppercase;
  border: 2px $palette-light solid;
  padding: 15px 28px;
  border-radius: 100px;
  font-family: $font-additional;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  transition: .3s;
  cursor: pointer;
  color: $palette-light;

  @media screen and (max-width: 1199px) {
    font-size: 14px;
    padding: 12px 24px;
  }

  @media screen and (max-width: 767px) {
    font-size: 12px;
    padding: 6px 19px;
  }

  &._inner {
    font-size: 16px;
    line-height: 16px;
    padding: 12px 21px;

    @media screen and (max-width: 991px) {
      font-size: 14px;
    }

    &._active {
      color: $palette-red;
    }

  }

  &._shadow {
    @media screen and (max-width: 767px) {
      background: $palette-dark_30;
    }
  }

  &.red {
    background: $palette-red;
    border-color: $palette-red;

    &:hover {
      color: $palette-light;
      background: $palette-red-dark;
      border-color: $palette-red-dark;
    }
  }

  &.gray {
    border-color: $palette-input;
    color: $palette-dark;

    &:hover {
      color: $palette-light;
      background: $palette-input;
      border-color: $palette-input;
    }

    &._inner {
      &:hover {
        color: $palette-red;
        background: none;
      }
    }
  }

  &.filter {
    border-color: $palette-input;
    background: $palette-input;
    color: $palette-light;

    &:hover {
      border-color: $palette-input;
      background: $palette-input;
      color: $palette-light;
    }
  }

  &:hover {
    background: $palette-light;
    color: $palette-dark;
    border-color: $palette-light;
  }
}
</style>