<template>
    <transition name="modal">
      <div v-if="show" class="modal" :style="cssPosition">
        <div class="modal-window">
          <div class="modal-window__close" @click="closeModal">
            <SvgIcon name="cross"/>
          </div>
          <div class="modal-window__body">
            <div class="modal-window__body-title" v-if="title">{{ title }}</div>
            <div class="modal-window__body-content">
              <slot/>
            </div>
          </div>
        </div>
      </div>
    </transition>
</template>

<script>
import SvgIcon from './SvgIcon';

export default {
  name: 'ModalWindow',
  props: {
    position: {
      type: String,
      default: 'fixed'
    },
    title: String
  },
  components: {SvgIcon},
  data: () => ({
    show: false
  }),
  computed: {
    cssPosition() {
      return {
        position: this.position
      };
    }
  },
  methods: {
    closeModal: function () {
      this.show = false;
			let baseUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
			history.pushState(null, null, baseUrl);
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 25;


  &-window {
    background: $palette-light;
    border-radius: 32px;
    width: 90%;
    max-width: 490px;
    max-height: 680px;
    position: absolute;
    padding: 40px 128px 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: content-box;

    @media screen and (max-width: 1365px) {
      max-width: 548px;
      padding: 40px 95px 30px;
    }

    @media screen and (max-width: 1199px) {
      max-width: 622px;
      padding: 40px 80px 30px;
    }

    @media screen and (max-width: 991px) {
      max-width: 470px;
      padding: 32px 64px 30px;
    }

    @media screen and (max-width: 767px) {
      max-width: unset;
      height: calc(100% - 50px);
      max-height: 100vh;
      width: 87%;
      left: 0;
      top: 0;
      transform: translate(0, 0);
      padding: 24px 16px;
      border-radius: 0 16px 0 0;
    }

    @media screen and (max-width: 575px) {
      width: 75%;
    }

    ._register & {
      height: 80%;
      max-width: 670px;
      padding: 40px 24px 30px;

      @media screen and (max-width: 991px) {
        padding: 32px 16px 40px;
      }

      @media screen and (max-width: 767px) {
        height: calc(100% - 70px);
        padding: 32px 0 40px;
      }
    }

    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: absolute;
      top: -24px;
      right: -24px;
      width: 32px;
      height: 32px;
      background: $palette-red;
      cursor: pointer;

      @media screen and (max-width: 767px) {
        top: 8px;
        right: -40px;
      }

      & svg {
        stroke: $palette-light;
        width: 16px;
        height: 15px;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      max-height: inherit;
      height: 100%;

      &-title {
        font-family: $font-additional;
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        margin-bottom: 24px;

        @media screen and (max-width: 1199px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      &-content {
        flex-grow: 1;
        height: 100%;
      }
    }
  }

  &-enter-active, &-leave-active {
    opacity: 0;
    transition: opacity .5s;
  }

  &-enter-to, &-leave {
    opacity: 1
  }
}
</style>
