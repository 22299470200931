import api from './api';
import TokenService from './token.service';

class AuthService {
	login({username, password}) {
		return api
			.post('/login_check', {
				username,
				password
			})
			.then((response) => {
				if (response.data.token) {
					TokenService.setUser(response.data);
				}

				return response.data;
			});
	}

	logout() {
		TokenService.removeUser();
	}

	register({username, email, password}) {
		return api.post('/auth/signup', {
			username,
			email,
			password
		});
	}

	async getUser() {
		let user = JSON.parse(localStorage.getItem('user'));
		return await api
			.get('/users/' + user.id, {})
			.then((response) => {
				if (response.data.status === 'success') {
					if (response.data.data.id) {
						TokenService.updateUser(response.data.data);
						return response.data;
					}
				}

				return {};
			});
	}
}

export default new AuthService();
