let data = [
	{
		text: 'ОПРЕДЕЛЕНИЕ ТЕРМИНОВ',
		items: [{
			text: 'В настоящей Политике конфиденциальности используются следующие термины:',
			items: [
				{text: '«Администрация сайта sales.coral-meat.ru (далее – Администрация сайта)» – уполномоченные сотрудники на управление сайтом, которые организуют и (или) осуществляет обработку персональных данных, а также определяют цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.'},
				{text: '«Персональные данные» – любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (субъекту персональных данных).'},
				{text: '«Обработка персональных данных» – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление доступа), обезличивание, блокирование, удаление, уничтожение персональных данных.'},
				{text: '«Конфиденциальность персональных данных» – обязательное для соблюдения Оператором или иным, получившим доступ к персональным данным, лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.'},
				{text: '«Пользователь сайта (далее Пользователь)» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.'},
				{text: '«Cookies» – небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.'},
				{text: '«IP-адрес» – уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.'}
			]
		}]
	},
	{
		text: 'ОБЩИЕ ПОЛОЖЕНИЯ',
		items: [
			{text: 'Использование Пользователем сайта означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.'},
			{text: 'В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта.'},
			{text: 'Настоящая Политика конфиденциальности применяется только к сайту sales.coral-meat.ru. Сайт не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте sales.coral-meat.ru.'},
			{text: 'Администрация сайта не несет ответственность за достоверность персональных данных, предоставляемых Пользователем сайта.'}
		]
	},
	{
		text: 'ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ',
		items: [
			{text: 'Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта sales.coral-meat.ru по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта.'},
			{text: 'Персональные данные, разрешенные к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путем заполнения специальных форм на сайте sales.coral-meat.ru.'},
			{
				text: 'Сайт защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы ("пиксель"):<ul><li>IP адрес;</li><li>информация из cookies;</li><li>информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);</li><li>время доступа;</li><li>адрес страницы, на которой расположен рекламный блок;</li><li>реферер (адрес предыдущей страницы).</li></ul>',
				items: [
					{text: 'Отключение cookies может повлечь невозможность доступа к частям сайта.'},
					{text: 'Сайт осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.'}
				]
			},
			{text: 'Любая иная персональная информация, неоговоренная выше (данные, собранные в специальных формах, используемые браузеры и операционные системы и т.д.), подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.'}
		]
	},
	{
		text: 'ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ',
		items: [{
			text: 'Персональные данные Пользователя Администрация сайта может использовать в целях:',
			items: [
				{text: 'Идентификации Пользователя, отправившего заявку, используя специальные формы на сайте.'},
				{text: 'Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования сайта, обработки запросов и заявок от Пользователя.'},
				{text: 'Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.'},
				{text: 'Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.'},
				{text: 'Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта.'},
				{text: 'Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени сайта.'},
				{text: 'Осуществления рекламной деятельности с согласия Пользователя.'}
			]
		}]
	},
	{
		text: 'СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ',
		items: [
			{text: 'Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.'},
			{text: 'Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.'},
			{text: 'При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.'},
			{text: 'Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.'},
			{text: 'Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.'}
		]
	},
	{
		text: 'ОБЯЗАТЕЛЬСТВА СТОРОН',
		items: [
			{
				text: 'Пользователь обязан:',
				items: [
					{text: 'Предоставить информацию о персональных данных, необходимую для пользования сайтом.'},
					{text: 'Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.'}
				]
			},
			{
				text: 'Администрация сайта обязана:',
				items: [
					{text: 'Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.'},
					{text: 'Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.'},
					{text: 'Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.'},
					{text: 'Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя, или его законного представителя, либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.'}
				]
			}
		]
	},
	{
		text: 'ОТВЕТСТВЕННОСТЬ СТОРОН',
		items: [
			{text: 'Администрация сайта, не исполнившая свои обязательства, несет ответственность за убытки, понесенные Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.',},
			{
				text: 'В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несет ответственность, если данная конфиденциальная информация:',
				items: [
					{text: 'Стала публичным достоянием до ее утраты или разглашения.'},
					{text: 'Была получена от третьей стороны до момента ее получения Администрацией сайта.'},
					{text: 'Была разглашена с согласия Пользователя.'},
				]
			}
		]
	},
	{
		text: 'РАЗРЕШЕНИЕ СПОРОВ',
		items: [
			{text: 'До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).'},
			{text: 'Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.'},
			{text: 'При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Российской Федерации.'},
			{text: 'К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Российской Федерации.'},
		]
	},
	{
		text: 'ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ',
		items: [
			{text: 'Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.'},
			{text: 'Новая Политика конфиденциальности вступает в силу с момента ее размещения на сайте sales.coral-meat.ru, если иное не предусмотрено новой редакцией Политики конфиденциальности.'},
			{text: 'Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать по адресу info@coral-meat.ru.'},
			{text: 'Действующая Политика конфиденциальности размещена на странице по адресу sales.coral-meat.ru.'},
		]
	}
];

export default {
	data
};