<template>
  <div v-html="content"></div>
<!--  <div class="delivery">
    <div class="delivery-container _payment">
      <h2>{{ content.payment.title }}</h2>
      <div v-html="content.payment.text"></div>
    </div>
    <div class="delivery-container _delivery">
      <h2>{{ content.delivery.title }}</h2>
      <div v-html="content.delivery.text"></div>
      <h3>{{ content.delivery.type.title }}</h3>
      <div class="delivery-container__list">
        <div class="delivery-container__list-item" v-for="(type, key) in content.delivery.type.items" :key="key">
          <img :src="type.image" :alt="type.title">
          <span>{{type.title}}</span>
        </div>
      </div>
    </div>
  </div>-->
</template>

<script>
//import templateData from '/static/data/template.js';
import AppHandlers from "@/mixins/AppHandlers";

export default {
  name: 'RulesPage',
  mixins: [
    AppHandlers
  ],
  data: () => ({
    //content: templateData.data.delivery.content
    content: ''
  }),
  async mounted() {
    this.$root.showPreloader();
    try {
      let response = await this.getDataWithoutToken('/pages/delivery');
      this.content = response.content;
    }
    catch(e) {
      this.content = e.response.data['hydra:description'];
    }
    this.$root.hidePreloader();
  }
};
</script>

<style lang="scss">
.delivery {
  &-container {
    max-width: 616px;
    margin-bottom: 40px;

    @media screen and (max-width: 991px) {
      max-width: 50%;
    }

    @media screen and (max-width: 767px) {
      max-width: 100%;
    }

    &__list {
      display: flex;
      justify-content: space-between;
      max-width: 440px;

      @media screen and (max-width: 575px) {
        flex-wrap: wrap;
        justify-content: space-around;
      }

      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        & img {
          max-width: 124px;
          max-height: 50px;
          margin-bottom: 15px;

          @media screen and (max-width: 575px) {
            max-height: 40px;
          }
        }
      }
    }

    &._delivery {
      @media screen and (max-width: 991px) {
        max-width: 100%;
      }
    }

    &._payment {
      & div {
        & p {
          &:first-child {
            @media screen and (max-width: 767px) {
              max-width: 50%;
            }

            @media screen and (max-width: 575px) {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>