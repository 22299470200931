<template>
  <div class="profile">
    <div class="profile-head">
      <div class="profile-head__navigation" v-if="mediaQuery({MinWidth: 768})">
        <ButtonItem text="Процесс торгов" link="/profile/reports" color="gray" class="profile-head__navigation-item _inner"/>
        <ButtonItem text="Активность пользователей" link="/profile/reports/activity" color="gray" class="profile-head__navigation-item _inner"/>
        <ButtonItem text="Выбранные типы продукции" color="gray" class="profile-head__navigation-item _inner _active"/>
      </div>
      <SelectList text="Выбранные типы продукции" class="profile-head__select" :show="showPopup" @togglePopup="this.showPopup = $event" v-if="mediaQuery({MaxWidth: 767})">
        <router-link class="_active" to="#" @click="this.showPopup = false">Выбранные типы продукции</router-link>
        <router-link to="/profile/reports/activity">Активность пользователей</router-link>
        <router-link to="/profile/reports">Процесс торгов</router-link>
      </SelectList>
    </div>
    <div class="sections-body" v-show="componentKey">
      <TableSimple :columns="columns" :items="items" :exclude-sort="['sections']" :key="componentKey"/>
    </div>
    <DownloadButton text="Выгрузить отчет" url="/get_users?export=true" class="profile-button"/>
  </div>
</template>

<script>
//import ReportData from '/static/data/reports.js';
import ButtonItem from '@/components/Elements/ButtonItem';
import TableSimple from '@/components/Elements/TableSimple';
import DownloadButton from '@/components/Elements/DownloadButton';
import MediaHandlers from '@/mixins/MediaHandlers';
import AppHandlers from '@/mixins/AppHandlers';
import SelectList from '@/components/Elements/SelectList';

export default {
  name: 'SectionsReport',
  components: {
    DownloadButton,
    TableSimple,
    ButtonItem,
    SelectList
  },
  mixins: [
    MediaHandlers,
    AppHandlers
  ],
  data: () => ({
    columns: {},
    items: {},
    showPopup: false,
    componentKey: 0
  }),
  async mounted() {
    this.$root.showPreloader();
    let response = await this.getData('/get_users');
    this.columns = response.template;
    this.items = response.items;
    this.componentKey++;
    this.$root.hidePreloader();
  }
};
</script>

<style scoped lang="scss">
.sections {
  &-body {
    border: 1px solid $palette-lightgray;
    border-radius: 16px;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
}
</style>

<style lang="scss">
.sections-body {
  & table tr th {
    font-family: $font-additional;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;

    &.id {
      @media screen and (max-width: 1250px) {
        width: 50px;
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>